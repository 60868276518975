.navbar,
.navbar-default,
.navbar-header,
.bg-light {
  background-color: var(--navbar);
  background-image: none !important;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
  min-width: 350px;

  // overflow-x: hidden;
  .nav-link {
    color: var(--text-colorone) !important;
  }

  .nav-link2 {
    color: rgb(0, 219, 0) !important;
  }


  .prize-nav {
    color: #00b8f9 !important;
    font-weight: bold;
    margin-left: -5px;
  }



  .nav-link:hover {
    color: var(--orange) !important;
  }

  @media screen and (max-width: 1300px) {
    .nav-link {
      font-size: 13px;
    }
  }

  .nav-link.active {
    color: var(--orange) !important;
  }

  .nav-link-sec {
    color: var(--text-colorone) !important;
  }

  .nav-link-sec:hover {
    color: var(--orange) !important;
  }

  .nav-link-sec.active {
    color: var(--orange) !important;
  }



  .dropdown-menu {
    background-color: var(--bg-dropdown);
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    min-width: 120px;
    z-index: 10000;
    text-align: right !important;
  }

  .navbar-main {
    height: 60px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.4);
    font-weight: 700 !important;
    font-family: "Titillium";

    .logo {
      padding-top: 4px;
      padding-left: 10px;
      width: auto;
      height: 40px;
    }
  }

  .icon-navbar-main {
    .icon-navbar-ui:hover {
      background-color: transparent !important;
    }
  }
}

.icon-navbar-ui-profile {
  background-color: transparent !important;
  color: var(--text-colorone) !important;
  font-size: 30px !important;
}

.icon-navbar-ui-profile:hover {
  background-color: transparent !important;
  color: var(--orange) !important;
}

// .MuiSvgIcon-root {
//
// }
.MuiSvgIcon-root:hover {
  background-color: transparent !important;
  color: var(--orange) !important;
}

.offcanvas-body {
  background-color: var(--navbar) !important;
  color: var(--text-colorone);
  padding-left: 20px !important;

  // padding-right: 60px !important;
  .dropdown-menu.show {
    background-color: var(--bg-colortwo);
    border: 0px;
    color: var(--text-colorone);
    padding-left: 20px !important;
  }

  .nav-link.active {
    color: var(--orange) !important;
  }
}

.offcanvas-title {
  color: var(--text-colorone);
}

.offcanvas-header {
  background-color: var(--bg-colorone) !important;
}

.btn-close {
  color: var(--text-colorone) !important;
  background: url(../../assets/images/close.svg) !important;
  height: 30px !important;
  width: 30px !important;
}

.navbar-toggler-icon {
  background-image: url(../../assets/images/menu.svg) !important;
  outline: none !important;
}

.logohere {
  background-image: var(--logo);
  background-position: center;
  background-size: 180px 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 200px;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
}

.modal-logout {
  .confirm-logout {
    background-color: var(--tradegreen);
    border-color: var(--tradegreen);
    color: var(--text-colorfifth) !important;

    &:hover {
      background-color: var(--text-colorfifth);
      border-color: var(--text-colorfifth);
      color: var(--tradegreen) !important;
    }
  }

  .cancel-logout {
    background-color: var(--tradered);
    border-color: var(--tradered);
    color: var(--text-colorfifth) !important;

    &:hover {
      background-color: var(--text-colorfifth);
      border-color: var(--text-colorfifth);
      color: var(--tradered) !important;
    }
  }
}

.login-navbar-btn {
  width: 70px;
  height: 40px;
  // margin-top: 120px;
  background-color: #ffee00 !important;
  border: 0 !important;
  color: black;
  font-size: 18px;
}

.login-navbar-btn:hover {
  background-color: #00b8f9 !important;
}

.gift-icon {
  width: 50px;
  margin-top: -5px;
}

.sun-icon {
  width: 50px;
  margin-right: -5px !important;
}

.navbartop-mobile {
  position: fixed;
  top: 15;
  z-index: 500;
}

.btn-top-icon {
  background-color: transparent;
  border: 0;
}