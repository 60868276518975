.announcement-main {
  background-image: url("../../assets/images/announcement-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-colorone);
  overflow: auto;
  margin-top: -40px;
  min-height: 80vh;

  .announcement-module {
    margin-top: 60px;
    min-height: 50vh;

    .announcement-title {
      color: var(--text-colorone);
      background-color: var(--bg-colorone);
    }

    .announcement-content {
      color: var(--text-colorone) !important;
      background-color: var(--bg-colorone);

      .table {
        color: var(--text-colorone) !important;
        background-color: var(--bg-colorone);
      }
    }
  }

  .MuiCardContent-root,
  .MuiCardContent-root {
    background-color: var(--bg-colorone) !important;
  }
}

.announcement-main-mobile {
  background-image: url("../../assets/images/announcement-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-colorone);
  overflow: auto;
  // margin-top: -40px;
  min-height: 100vh;

  .announcement-module {
    .announcement-main {
      background-image: url("../../assets/images/announcement-bg.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: var(--bg-colorone);
      overflow: auto;
      // margin-top: -40px;
      min-height: 100vh;

      .announcement-module {
        margin-top: 60px;
        min-height: 50vh;

        .announcement-title {
          color: var(--text-colorone);
          background-color: var(--bg-colorone);
        }

        .announcement-content {
          color: var(--text-colorone) !important;
          background-color: var(--bg-colorone);

          .table {
            color: var(--text-colorone) !important;
            background-color: var(--bg-colorone);
          }
        }
      }

      .MuiCardContent-root,
      .MuiCardContent-root {
        background-color: var(--bg-colorone) !important;
      }
    }

    margin-top: 60px;
    min-height: 50vh;

    .announcement-title {
      color: var(--text-colorone);
      background-color: var(--bg-colorone);
    }

    .announcement-content {
      color: var(--text-colorone) !important;
      background-color: var(--bg-colorone);

      .table {
        color: var(--text-colorone) !important;
        background-color: var(--bg-colorone);
      }
    }
  }

  .MuiCardContent-root,
  .MuiCardContent-root {
    background-color: var(--bg-colorone) !important;
  }
}