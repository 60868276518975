.home-mobile-main {
    background-color: var(--bg-colorone);
    min-height: 100vh;

    .h-top-btn {
        width: 120px;
        height: 40px;
        margin-top: 20px;
        background-color: #ffee00;
        border: 0;
        color: black;
        font-size: 13px;
    }

    .h-top-btn:hover {
        background-color: #00b8f9 !important;
    }

    .home-top-texts {
        color: var(--text-colorone);
    }

    .text-btn-home-icons {
        color: var(--text-colorone);
        font-size: 12px;
    }

    .btn-top-icon {
        background-color: transparent;
        border: 0;
    }



    .market-mobile-home {
        margin-top: 50px;
        min-height: 400px;
        // padding-bottom: 150px;
        background-color: var(--bg-colorone);
    }

    .footer-mobile-home {
        background-color: #00b8f9;
        min-height: 40vh;
        padding-bottom: 100px;
    }

    .MuiPaper-root,
    .MuiPaper-elevation,
    .MuiPaper-rounded,
    .MuiPaper-elevation1,
    .MuiAccordion-root,
    .MuiAccordion-rounded,
    .MuiAccordion-gutters,
    .MuiPaper-root-MuiAccordion-root {
        background-color: #00abe9 !important;
    }

    .MuiTypography-root,
    .MuiTypography-body1,
    .MuiTypography-root {
        color: #fff !important;
    }

    ul {
        color: #fff !important;

        li,
        .nav-link-footer {
            text-decoration: none !important;
            color: #fff !important;

        }
    }


}

.btn-home-market {

    .market-table-btn2 {
        color: var(--text-colorone) !important;
    }
}

.application-section-mobile {
    min-height: 60vh;
    padding-top: 120px;
    background-color: var(--bg-colorone);
    color: var(--text-colorone);

    @media (max-width: 769px) {
        padding-top: 20px;
    }

    .btn-app-main {
        width: 200px !important;
        margin-left: 30px;
        margin-bottom: 45px;
        height: 35px;
        color: #383838 !important;
        padding: 0;

    }

    .appli {
        max-width: 400px;
        padding-top: 25px;
        font-size: 12px;
        padding-bottom: 25px;
    }

    .app-section {
        @media (max-width: 769px) {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            width: 200px;
        }
    }

    .app-btns {
        display: block;
        color: #383838 !important;
        border: 0 !important;
        height: 60px;
        align-self: center;
        background-color: #f9d400 !important;
        border-radius: 05px;
        padding: 10px;
        text-decoration: none;
        line-height: 2.4;
        width: 220px !important;

        &:hover {
            background-color: #00b8f9 !important;
            color: #fff !important;
        }
    }
}