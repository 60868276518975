.market-mobile-module {
    margin-top: -30px;
    min-height: 100vh;
    overflow: auto;
    background-color: var(--bg-colorone);

    .market-mobile-main {
        margin-top: 70px;
        margin-bottom: 130px;
        background-color: var(--bg-colorone);
    }
}

.navbar-mobile-bot {
    position: fixed;
    bottom: 15px;
}

.appbar-home {
    .nav-mobile-bot-textbtn {
        color: var(--text-colorone) !important;
        font-size: 9px;
    }

    .nav-mobile-bot-textbtng {
        color: var(--tradegreen) !important;
        font-size: 9px;
    }

    .MuiPaper-root-MuiAppBar-root {
        background-color: var(--bg-colorone) !important;
    }

    .MuiPaper-root,
    .MuiPaper-elevation,
    .MuiPaper-elevation4,
    .MuiAppBar-root,
    .MuiAppBar-colorPrimary,
    .MuiAppBar-positionStatic,
    .MuiPaper-root-MuiAppBar-root {
        background-color: var(--bg-colortwo) !important;
        border-radius: 15px;
    }

    border-radius: 15px;
    box-shadow: 0px 10px 13px 13px rgba(0, 0, 0, 0.1);

}