.withdraw-both {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -30px;
  overflow: auto;
  background-color: var(--bg-colorone);

  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .MuiSelect-select {
    // direction: ltr !important;
    font-family: sans-serif !important;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input,
  .MuiInputBase-input-MuiOutlinedInput-input {
    // text-align: end !important;
    // font-family: sans-serif !important;
    direction: ltr;
  }

  .MuiButtonBase-root,
  .MuiMenuItem-root,
  .MuiMenuItem-gutters,
  .MuiMenuItem-root,
  .MuiMenuItem-gutters,
  .MuiButtonBase-root-MuiMenuItem-root {
    direction: ltr !important;
    // font-family: sans-serif !important;
  }

  .withdrawModule {
    margin-top: 10px;
  }

  .MuiTabs-flexContainer {
    background-color: var(--bg-colorone);
    height: 50px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
  }

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .MuiButtonBase-root-MuiTab-root {
    color: var(--text-colorone);
  }

  .Mui-selected {
    color: var(--text-colortwo) !important;
    background-color: var(--orange);
  }

  .MuiTouchRipple-root {
    color: var(--text-colortwo) !important;
  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
    text-align: end !important;
  }

  .withdraw-main {
    .withdraw {
      min-height: 80vh;
      margin-top: 60px;
    }

    .withdraw-module {
      color: var(--text-colorone);
      min-height: 600px;
      width: 700px;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .withdraw-texts {
      color: var(--text-colorone) !important;
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .withdraw-bottom {
      h6 {
        font-size: 15px !important;
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around !important;
    padding: 5px !important;
    background-color: var(--bg-colorone) !important;
    border-top: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    .confirm-withdraw {
      background-color: var(--tradegreen);
      border-color: var(--tradegreen);
      color: var(--text-colorfifth);

      &:hover {
        background-color: var(--text-colorfifth);
        border-color: var(--text-colorfifth);
        color: var(--tradegreen);
      }
    }

    .cancel-withdraw {
      background-color: var(--tradered);
      border-color: var(--tradered);
      color: var(--text-colorfifth);

      &:hover {
        background-color: var(--text-colorfifth);
        border-color: var(--text-colorfifth);
        color: var(--tradered);
      }
    }
  }

  .withdraw-texts {
    color: var(--text-colorone) !important;
  }

  .MuiInputBase-root,
  .MuiSelect-icon {
    color: var(--text-colorone) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--text-colorone) !important;
  }



  .when-error {
    height: 100vh;
    // background-color: var(--bg-colorone);
    margin-top: -50px;
    overflow: auto;

    .error-module {
      margin-top: 100px;
      min-height: 300px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

      .attention-withdraw {
        text-align: center;
        padding-top: 50px;
        font-size: 20px !important;
      }
    }

    .error-btn {
      padding-top: 50px;
      text-align: center;

      .btn-withdraw-error {
        background: var(--bg-colorone);
        margin-left: 0.5rem;
        color: var(--text-colorone);
        font-size: 18px;
        font-weight: 500;
        width: 200px;
        border-radius: 5px;
        border: 1px var(--bg-colortwo) solid;
        -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
        -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
        box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      }

      .btn-withdraw-error:hover {
        background: var(--btn-one-hover);
        color: var(--btn-one);
        transition: all 0.5s;
        border: 1px var(--btn-one) solid;
      }
    }
  }
}

.withdraw-both-mobile {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  // margin-top: -30px;
  overflow: auto;
  background-color: var(--bg-colorone);

  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .MuiSelect-select {
    // direction: ltr !important;
    font-family: sans-serif !important;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input,
  .MuiInputBase-input-MuiOutlinedInput-input {
    // text-align: end !important;
    // font-family: sans-serif !important;
    direction: ltr;
  }

  .MuiButtonBase-root,
  .MuiMenuItem-root,
  .MuiMenuItem-gutters,
  .MuiMenuItem-root,
  .MuiMenuItem-gutters,
  .MuiButtonBase-root-MuiMenuItem-root {
    direction: ltr !important;
    // font-family: sans-serif !important;
  }

  .withdrawModule {
    margin-top: 60px;
  }

  .MuiTabs-flexContainer {
    background-color: var(--bg-colorone);
    height: 50px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
  }

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .MuiButtonBase-root-MuiTab-root {
    color: var(--text-colorone);
  }

  .Mui-selected {
    color: var(--text-colortwo) !important;
    background-color: var(--orange);
  }

  .MuiTouchRipple-root {
    color: var(--text-colortwo) !important;
  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
    text-align: end !important;
  }

  .withdraw-main {
    .withdraw {
      min-height: 80vh;
      margin-top: 60px;
    }

    .withdraw-module {
      color: var(--text-colorone);
      min-height: 600px;
      width: 700px;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .withdraw-texts {
      color: var(--text-colorone) !important;
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .withdraw-bottom {
      h6 {
        font-size: 15px !important;
      }
    }
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around !important;
    padding: 5px !important;
    background-color: var(--bg-colorone) !important;
    border-top: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    .confirm-withdraw {
      background-color: var(--tradegreen);
      border-color: var(--tradegreen);
      color: var(--text-colorfifth);

      &:hover {
        background-color: var(--text-colorfifth);
        border-color: var(--text-colorfifth);
        color: var(--tradegreen);
      }
    }

    .cancel-withdraw {
      background-color: var(--tradered);
      border-color: var(--tradered);
      color: var(--text-colorfifth);

      &:hover {
        background-color: var(--text-colorfifth);
        border-color: var(--text-colorfifth);
        color: var(--tradered);
      }
    }
  }

  .withdraw-texts {
    color: var(--text-colorone) !important;
  }

  .MuiInputBase-root,
  .MuiSelect-icon {
    color: var(--text-colorone) !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--text-colorone) !important;
  }



  .when-error {
    height: 100vh;
    // background-color: var(--bg-colorone);
    margin-top: -50px;
    overflow: auto;

    .error-module {
      margin-top: 100px;
      min-height: 300px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

      .attention-withdraw {
        text-align: center;
        padding-top: 50px;
        font-size: 20px !important;
      }
    }

    .error-btn {
      padding-top: 50px;
      text-align: center;

      .btn-withdraw-error {
        background: var(--bg-colorone);
        margin-left: 0.5rem;
        color: var(--text-colorone);
        font-size: 18px;
        font-weight: 500;
        width: 200px;
        border-radius: 5px;
        border: 1px var(--bg-colortwo) solid;
        -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
        -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
        box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      }

      .btn-withdraw-error:hover {
        background: var(--btn-one-hover);
        color: var(--btn-one);
        transition: all 0.5s;
        border: 1px var(--btn-one) solid;
      }
    }
  }
}

.attention-withdraw {
  font-size: 15px !important;
  text-align: justify;
  color: var(--text-colorone) !important;
  margin-bottom: 5px;

  span {
    color: var(--tradered) !important;
  }
}

.withdraw-tab {
  font-family: "Titillium" !important;
}

.wit-his-main {
  min-height: 700px;
  background-color: var(--bg-colortwo);
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  border-radius: 15px;
  padding: 30px;
}

.completed {
  color: var(--tradegreen) !important;
}

.rejected {
  color: var(--tradered) !important;
}

.cancelled {
  color: var(--text-colorone) !important;
}

.withdraw-both .when-error .error-btn .btn-withdraw-error {
  background: var(--bg-colorone) !important;
  margin-left: 0.5rem;
  color: var(--text-colorone) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 200px !important;
  height: 70px !important;
  border-radius: 5px;
  border: 1px var(--bg-colortwo) solid;
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  line-height: normal !important;
}

.confirmation-main {
  height: 120vh;
  background-color: rgb(62, 62, 62);
  margin-top: -20px;
  overflow: auto;

  .confirmation-module {
    min-height: 500px;
    min-width: 300px;
    max-width: 500px;
    background-color: var(--bg-colortwo);
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-top: 100px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    h5 {
      padding-top: 20px;
      margin-left: 20px;
      color: var(--text-colorone);
    }

    h6 {
      padding-top: 20px;
      margin-left: 20px;
      color: var(--text-colorone);
    }

    .confirm-loader {
      width: 100%;
      text-align: center;
      margin-top: 150px;
    }
  }
}

.fee-deposit-money h6 {
  color: var(--text-colorone) !important;
}

.available-amount {
  color: var(--text-colorone) !important;
}

.button-copy-wh {
  font-size: 10px !important;
}

.deposit-history-main-each {
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  min-height: 130px;
  color: var(--text-colorone);
  font-weight: normal;

  .success-text {
    color: #00b8f9;
    font-size: 16px;
  }

  .txid-each-mobile {
    word-break: break-all;
    font-size: 11px;

  }

  .button-copy {
    background-color: #00b8f9;
    color: white !important;
    padding: 0;
    margin: 0;
  }
}

.deposit-history-main-eachsite {
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  min-height: 130px;
  // max-width: 650px;
  color: var(--text-colorone);
  font-weight: normal;

  .success-text {
    color: #00b8f9;
    font-size: 18px;
  }

  .txid-each-mobile {
    word-break: break-all;
    font-size: 14px;

  }

  .button-copy {
    background-color: #00b8f9;
    color: white !important;
    padding: 0;
    margin: 0;
  }
}