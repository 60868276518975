.loading-body {
    margin-top: 350px;
    height: 100vh;
    width: 100vw;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 8500;

    p {
        font-family: "Titillium" !important;
        font-size: 18px;
        color: #313131;
        position: absolute;
        top: 200px;
        font-weight: 500;
    }
}

.loading {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    width: 170px;
    position: relative;
    margin: 0px;
    padding: 0px;
    z-index: 8500;
}

.circle {
    border: 5px transparent solid;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 69%;
}

.cyan {
    top: 0px;
    border-top: 5px #0095f9 solid;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation: #0095f9 1.5s infinite;
    animation: cyan 1.5s infinite;
}


.cyan:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #0095f9;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    -webkit-box-shadow: 0px 0px 20px #0095f9;
    box-shadow: 0px 0px 20px #0095f9;
}

.cyan:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #00b7f900;
    top: 10px;
    left: 11px;
    border-radius: 69%;
}

.magenta {
    left: 0px;
    bottom: 0px;
    border-top: 5px #f9d400 solid;
    -webkit-animation: #f9d400 1.5s infinite;
    animation: magenta 1.5s infinite;
}

.magenta:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #f9d400;
    border-radius: 69%;
    right: 5px;
    top: 10px;
    -webkit-box-shadow: 0px 0px 20px #f9d400;
    box-shadow: 0px 0px 20px #f9d400;
}

.magenta:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: #f9d40000;
    top: 10px;
    left: 11px;
    border-radius: 69%;
}

.yellow {
    right: 0px;
    bottom: 0px;
    border-top: 5px rgb(226, 0, 68) solid;
    -webkit-animation: rgb(226, 0, 68) 1.5s infinite;
    animation: yellow 1.5s infinite;
}

.yellow:after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: rgb(226, 0, 68);
    border-radius: 69%;
    right: 5px;
    top: 10px;
    -webkit-box-shadow: 0px 0px 20px rgb(226, 0, 68);
    box-shadow: 0px 0px 20px rgb(226, 0, 68);
}

.yellow:before {
    content: " ";
    width: 5px;
    height: 5px;
    position: absolute;
    background: rgba(255, 255, 0, 0);
    top: 10px;
    left: 11px;
    border-radius: 69%;
}