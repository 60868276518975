.instant-main {
  background-color: var(--bg-colorone);
  background-image: url('../../../assets/images/instant.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 130vh;
  margin-top: -30px;
  overflow: auto;

  .instant-module {
    background-color: var(--bg-colortwo);
    background-image: url('../../../assets/images/instant.svg');
    background-repeat: no-repeat;
    background-position: bottom, center;
    background-size: cover;
    height: 720px;
    width: 500px;
    margin-top: 50px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    color: var(--text-colorone);

    @media (max-width: 500px) {
      width: 350px;
    }

    .top-token-instant {
      background-color: var(--bg-colorthree);
      width: 119px;
      height: 37px;
      display: flex;
      justify-content: space-between;
      border-radius: 35px;
      align-items: center;
      padding: 10px;
    }

    .price-amount {
      direction: ltr !important;
    }
  }

  .buy-btn-instant {
    border-bottom: 3px rgb(11, 168, 11) solid;
    text-align: center;

  }

  .sell-btn-instant {
    border-bottom: 3px rgb(245, 3, 75) solid;
    text-align: center;
  }

  .btn-final-instant-buy {
    background-color: rgb(11, 168, 11) !important;
    color: white !important;
  }

  .btn-final-instant-sell {
    background-color: rgb(245, 3, 75) !important;
    color: white !important;
  }

}

.available-section-instant-buy {
  background-color: rgba(11, 168, 11, 0.171);
  padding: 5px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.available-section-instant-sell {
  background-color: rgb(245, 3, 75, 0.171);
  padding: 5px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.h6-instant-fz {
  font-size: 13px !important;
}

.text-error {
  height: 15px !important;
  padding-top: 5px !important;
  color: red;
}

.instant-his-main {
  height: 400px;
  overflow-y: scroll;
}

.top-gateway-btn {
  color: var(--text-colortwo) !important;
  background-color: transparent !important;
  width: 40px !important;
  height: 25px !important;
}

.dropdown-in-instant {
  // min-width: 115px !important;
  text-align: center !important;
  background-color: var(--bg-colorone) !important;
}

.dropdownitem-in-instant {
  color: white !important;

}

.dropdownbtn-in-instant {
  background-color: transparent !important;
  color: white !important;
  border: 0 !important;
  height: 45px;
  padding: 0;
  margin: 0;
}

.dropdown-in-instant.dropdown-menu.show {
  background-color: var(--bg-colorone) !important;
  border: 0;
  justify-content: center;
  margin-left: -9px;
}

.dropdown-in-instant.dropdown-menu.show:hover {
  background-color: var(--bg-colorone) !important;

}

.instant-main {

  .dropdown-menu {

    --bs-dropdown-link-hover-bg: #e9ecef00 !important;
    --bs-dropdown-link-active-bg: #0d6dfd00 !important;
  }

  .dropdown-toggle::after {
    font-size: 20px;
    color: var(--text-colorone) !important;
  }
}

.input-instant-ltr {
  direction: ltr !important;
  text-align: start !important;

}

input#input-instant-ltr {
  text-align: start !important;
  direction: ltr !important;
}

.navbar-mobile-bot {
  position: fixed;
  bottom: 15px;
}

.instant-his-main-mobile {
  font-size: 13px;
  background-color: var(--bg-colortwo);
  padding: 10px;
  border-radius: 15px;

  p {
    padding: 0;
    margin: 0;
  }
}