.market-main {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 800px;
  margin-top: -30px;
  background-color: var(--bg-colorone);
  overflow: auto;
  font-family: 'Roboto' !important;

  .market-table {
    margin-bottom: 50px;
    margin-top: 60px;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    min-height: 800px;
  }

  .table-container-wrapper {
    max-height: 750px;
    overflow-y: auto;
  }

  h5 {
    color: var(--text-colorone);
    margin-bottom: 20px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
  }

  .Mui-selected {
    color: var(--text-colorone) !important;
  }

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .Mui-selected,
  .MuiTouchRipple-root {
    color: var(--text-colortwo);
    font-size: 18px;


  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
  }

  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
    font-size: 14px !important;

    @media (max-width: 650px) {
      font-size: 12px !important;
    }
  }
}




.search-main {

  .MuiInputBase-root,
  .MuiInput-root,
  .MuiInput-underline,
  .MuiInputBase-colorPrimary,
  .searchbar,
  .MuiInputBase-root-MuiInput-root {
    height: 35px !important;
    background-color: var(--bg-colorone);
    color: var(--text-colorone);
    direction: ltr !important;

    .searchbar {
      background-color: var(--bg-colorone);
      color: var(--text-colorone);
      direction: ltr !important;
    }
  }
}