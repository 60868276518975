.kycpage-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .kycpage-module {
    margin-top: 100px;
    background-color: var(--bg-colorone);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    margin-bottom: 80px;
    background-image: url("../../assets/images/kyc-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .kycpage-top {
      color: var(--text-colorone);
      text-align: justify;
    }

    .kyc-column {
      min-height: 850px;
      margin: 5px;
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--text-colorone);

      .kyc-cl-top {
        text-align: center;
        width: 100%;
        height: 150px;
        background-color: var(--bg-colortwo);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        -webkit-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        color: #0093ff !important;
      }

      .kyc-cl-mid {
        width: 100%;
        height: 60px;
        background-color: #00b8f9;

        display: flex;
        justify-content: center;
        -webkit-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);

        .align-mid {
          justify-content: center;
          display: flex;
        }
      }

      .kyc-cl-bot {
        width: 100%;
        background-color: var(--bg-colorone);
        min-height: 630px;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        padding: 10px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .kyc-cl-topv {
        background-image: url("../../assets/images/vipbg.svg");
        background-position: center;
        background-size: cover;
        color: rgb(175, 136, 51) !important;
        text-align: center;
        width: 100%;
        height: 150px;
        background-color: #09313f;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;


      }

      .kyc-cl-midv {
        width: 100%;
        height: 60px;
        background-color: #ffee00;
        display: flex;
        justify-content: center;

        .btn-in-kycpagev {
          height: 40px;
          font-size: 13px !important;
          background-color: var(--bg-colorone) !important;
          color: var(--text-colorone) !important;
          border: 0px !important;
          margin-top: 9px;

          &:hover {
            background-color: var(--trade3rd) !important;
            color: var(--text-colorone) !important;
          }
        }
      }

      .kyc-cl-botv {
        width: 100%;
        background-color: #fff;
        min-height: 550px;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        padding: 10px;
        background-image: url("../../assets/images/vipbgbot.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        p {
          color: #09313f !important;
          text-align: justify;
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }
  }

  .btn-in-kycpage {
    height: 40px;
    font-size: 13px !important;
    background-color: var(--bg-colorone) !important;
    color: var(--text-colorone) !important;
    border: 0px !important;
    margin-top: 9px;

    &:hover {
      background-color: var(--trade3rd) !important;
      color: var(--text-colorone) !important;
    }
  }

  p {
    font-size: 12px;
  }

  .access {
    margin-top: 65px;
  }

  .access2 {
    margin-top: 56px;
  }

  .accept {
    font-size: 15px;
  }

  .line-span {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    margin-top: 79px;
    margin-bottom: -50px;
  }

  .line-span2 {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    // margin-top: 50px;
    margin-bottom: 14px;
  }

  .line-span3 {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    margin-top: 62px;
    margin-bottom: -43px;
  }
}


.kycpage-main-mobile {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: 0px;
  overflow: auto;

  .kycpage-module {
    margin-top: 40px;
    background-color: var(--bg-colorone);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    margin-bottom: 80px;
    background-image: url("../../assets/images/kyc-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .kycpage-top {
      color: var(--text-colorone);
      text-align: justify;
    }

    .kyc-column {
      min-height: 850px;
      margin: 5px;
      width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--text-colorone);

      .kyc-cl-top {
        text-align: center;
        width: 100%;
        height: 150px;
        background-color: var(--bg-colortwo);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        -webkit-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        color: #0093ff !important;
      }

      .kyc-cl-mid {
        width: 100%;
        height: 60px;
        background-color: #00b8f9;

        display: flex;
        justify-content: center;
        -webkit-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        -moz-box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);
        box-shadow: inset 0px 0px 12px -1px rgba(0, 0, 0, 0.48);

        .align-mid {
          justify-content: center;
          display: flex;
        }
      }

      .kyc-cl-bot {
        width: 100%;
        background-color: var(--bg-colorone);
        min-height: 630px;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        padding: 10px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .kyc-cl-topv {
        background-image: url("../../assets/images/vipbg.svg");
        background-position: center;
        background-size: cover;
        color: rgb(175, 136, 51) !important;
        text-align: center;
        width: 100%;
        height: 150px;
        background-color: #09313f;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;


      }

      .kyc-cl-midv {
        width: 100%;
        height: 60px;
        background-color: #ffee00;
        display: flex;
        justify-content: center;

        .btn-in-kycpagev {
          height: 40px;
          font-size: 13px !important;
          background-color: var(--bg-colorone) !important;
          color: var(--text-colorone) !important;
          border: 0px !important;
          margin-top: 9px;

          &:hover {
            background-color: var(--trade3rd) !important;
            color: var(--text-colorone) !important;
          }
        }
      }

      .kyc-cl-botv {
        width: 100%;
        background-color: #fff;
        min-height: 550px;
        -webkit-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        -moz-box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        box-shadow: 0px 10px 32px -6px rgba(84, 84, 84, 0.68);
        padding: 10px;
        background-image: url("../../assets/images/vipbgbot.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        p {
          color: #09313f !important;
          text-align: justify;
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }
  }

  .btn-in-kycpage {
    height: 40px;
    font-size: 13px !important;
    background-color: var(--bg-colorone) !important;
    color: var(--text-colorone) !important;
    border: 0px !important;
    margin-top: 9px;

    &:hover {
      background-color: var(--trade3rd) !important;
      color: var(--text-colorone) !important;
    }
  }

  p {
    font-size: 12px;
  }

  .access {
    margin-top: 65px;
  }

  .access2 {
    margin-top: 56px;
  }

  .accept {
    font-size: 15px;
  }

  .line-span {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    margin-top: 79px;
    margin-bottom: -50px;
  }

  .line-span2 {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    // margin-top: 50px;
    margin-bottom: 14px;
  }

  .line-span3 {
    border-top: #00b8f9 10px solid;
    width: 100%;
    text-align: center;
    margin-top: 62px;
    margin-bottom: -43px;
  }
}

.input-card-kyc {
  background-color: var(--bg-colorone);
  color: var(--text-colorone);
  border-radius: 5px;
  box-shadow: none !important;
  padding-top: 3px;
  direction: ltr !important;
}

.bank-text-kyc {
  font-size: 14px;
  padding-top: 7px;
}

.btn-in-kyc7 {
  height: 65px;
  font-size: 13px !important;
  background-color: var(--tradegreen) !important;
  color: var(--bg-colorone) !important;
  border: 0px !important;

  &:hover {
    background-color: var(--trade3rd) !important;
    color: var(--text-colorone) !important;
  }
}

.btn-in-kyc7 {
  @media screen and (max-width: 600px) {
    height: 75px;
  }
}

.custom-modal-kyc {
  max-height: 500px !important;
}

.kyc-lvl3-h6 {
  text-align: justify;
  font-size: 14px;
}

.kyc-lvl3-h6-text {
  color: white !important;
  font-size: 16px;
}

.kyc-lvl3-h6-main {
  background-color: #09313f;
  padding: 8px;
  margin-top: 10px;
  border-radius: 15px;
  text-align: justify !important;
}

.camera-btn {
  color: #2b2b2b !important;
  background-color: var(--tradegreen) !important;
  border: 0px !important;
}

.camera-btn:hover {
  color: #d8d8d8 !important;
  border: 0px !important;
  background-color: #2b2b2b !important;
}

.kyc-waiting-text {
  color: #2b2b2b;
  margin-top: 15px;
}

.ul-kyc-lvl3 {
  li {
    font-size: 18px;
  }
}

.img0lvl3 {
  background-color: #fff;
  width: 150px;
  text-align: center;
}

.imglvl3main {
  width: 100%;
  justify-content: center;
  display: flex;
}

.cardnumber-table {
  direction: ltr !important;


}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight.MuiTableCell-sizeMedium.table-row.MuiTableCell-root {
  direction: ltr !important;
}

.navbar-mobile-bot {
  position: fixed;
  bottom: 15px;
}

.kyc-lvl2 {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -30px;
  overflow: auto;
  color: var(--text-colorone);

  .kyc-lvl2-module {
    margin-top: 50px !important;

  }
}

.input-birth-picker {
  background-color: var(--bg-colorone);

}

.__datepicker-input {

  button,
  input,
  optgroup,
  select,
  textarea {
    color: var(--text-colorone) !important;
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: 1px solid rgba(181, 181, 181, 0.582);
    padding-right: 15px;
    font-weight: 600;
  }

}

.input-card-kyc {
  height: 45px;
  width: 100%;
}

.__datepicker.__datepicker-theme-orange * {
  --primary: rgba(0, 213, 255, 0.908) !important;
  --secondary: rgba(0, 0, 0, 0.3);
}

.kyc-lvl3-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -30px;
  overflow: auto;
  color: var(--text-colorone);

  .kyc-lvl3-module {
    margin-top: 50px !important;

  }
}

.select,
.cities,
.form-select {
  color: var(--text-colorone) !important;
  background-color: var(--bg-colorone) !important;
}

input#iban {
  direction: ltr;
}