.support-main {
  min-height: 100vh;
  height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -40px;
  overflow: auto;


  .support-module {
    margin-top: 100px;
    color: var(--text-colorone);
    background-color: var(--bg-colortwo);
    padding: 20px;

  }

}

.support-main-mobile {
  min-height: 100vh;
  height: 100vh;
  background-color: var(--bg-colorone);
  overflow: auto;


  .support-module-mobile {
    margin-top: 100px;
    color: var(--text-colorone);
    background-color: var(--bg-colortwo);
    padding: 20px;

  }

}

.support-container {
  padding: 25px;
  background-color: var(--bg-colortwo);
  color: var(--text-colorone);
  height: 700px;

}


.conversation-main {
  background-color: var(--bg-colortwo);
  // margin-top: -50px;
  // min-height: 100vh;
  // overflow: auto;
  margin-bottom: 20px;


  .conversation-module {

    background-color: var(--conversation-bg);
    // margin-top: 60px;
    padding: 15px;
    border-radius: 10px;
  }

  .MuiPaper-root {
    background-color: var(--conversation-bg2);

  }

}

.conversation-form {
  margin-top: 20px;
  width: 99%;
  color: var(--text-colorone) !important;

  .MuiFormLabel-root,
  .MuiInputLabel-root,
  .MuiInputLabel-formControl,
  .MuiInputLabel-animated,
  .MuiInputLabel-outlined,
  .MuiFormLabel-colorPrimary,
  .MuiInputLabel-root,
  .MuiInputLabel-formControl,
  .MuiInputLabel-animated,
  .MuiInputLabel-outlined,
  .MuiFormLabel-root-MuiInputLabel-root {
    color: var(--text-colorone) !important;
  }
}

.conversation-btn {
  background-color: orange !important;
  color: wheat !important;
  border: 0 !important;
}

.support-btn {
  background-color: var(--trade7th) !important;
  color: var(--text-colorone) !important;
  border: 0 !important;
}

.support-modal {

  .modal-dialog {
    top: 100px !important;
  }
}

.form-ticket {
  color: var(--text-colorone) !important;
}

.ticket-title-selection {
  color: var(--text-colorone) !important;
  background-color: var(--bg-colorone) !important;
}

.sup-mobile-card {
  background-color: var(--bg-colortwo);
  margin-top: 50px;
  padding: 10px;
  border-radius: 15px;
  color: var(--text-colorone);
}