.transfer-main {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -30px;
  overflow: auto;
  width: 100%;
  background-color: var(--bg-colorone);

  .transfer-module {
    min-height: 400px;
    margin-top: 120px;
    min-width: 300px;
    max-width: 650px;
    background-color: var(--bg-colorone);
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    h5,
    h6 {
      color: var(--text-colorone);
    }

    .available-trans {
      text-align: end;
    }

    .MuiTypography-root,
    .MuiTypography-body1,
    .MuiFormControlLabel-label,
    .MuiTypography-root {
      color: var(--text-colorone);
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .MuiFormLabel-root,
    .MuiInputLabel-root,
    .MuiInputLabel-formControl,
    .MuiInputLabel-animated,
    .MuiInputLabel-shrink,
    .MuiInputLabel-outlined,
    .MuiFormLabel-colorPrimary,
    .MuiInputLabel-root,
    .MuiInputLabel-formControl,
    .MuiInputLabel-animated,
    .MuiInputLabel-shrink,
    .MuiInputLabel-outlined,
    .MuiFormLabel-root-MuiInputLabel-root {
      color: var(--text-colorone) !important;
    }
  }

  .transfer-note {
    text-align: justify;
    margin-top: 20px;
    color: var(--text-colorone);
  }
}


.transfer-main-mobile {
  background: var(--bg-digi);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 150vh;
  // margin-top: -30px;
  overflow: auto;
  width: 100%;
  background-color: var(--bg-colorone);

  .transfer-module {
    min-height: 400px;
    margin-top: 120px;
    min-width: 300px;
    max-width: 650px;
    background-color: var(--bg-colorone);
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    h5,
    h6,
    p {
      color: var(--text-colorone);
      font-size: 12px;
    }

    .available-trans {
      text-align: end;
    }

    .MuiTypography-root,
    .MuiTypography-body1,
    .MuiFormControlLabel-label,
    .MuiTypography-root {
      color: var(--text-colorone);
      font-size: 12px !important;
      font-weight: normal !important;
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .MuiFormLabel-root,
    .MuiInputLabel-root,
    .MuiInputLabel-formControl,
    .MuiInputLabel-animated,
    .MuiInputLabel-shrink,
    .MuiInputLabel-outlined,
    .MuiFormLabel-colorPrimary,
    .MuiInputLabel-root,
    .MuiInputLabel-formControl,
    .MuiInputLabel-animated,
    .MuiInputLabel-shrink,
    .MuiInputLabel-outlined,
    .MuiFormLabel-root-MuiInputLabel-root {
      color: var(--text-colorone) !important;
    }
  }

  .transfer-note {
    text-align: justify;
    margin-top: 20px;
    color: var(--text-colorone);
    font-size: 13px;
  }
}