.chart-main {
  width: 100vw;
  //   overflow: hidden;
  //   margin-top: -100px;
  //   margin-right: -172px;
  background-color: transparent !important;
}

.chart {
  position: relative;
  opacity: 0.4;
  width: 100vw;
  margin-right: -50px;
  z-index: -1;
}

.chart-main2 {
  width: 98vw !important;
  overflow: hidden;
  //   margin-top: -100px;
  //   margin-right: -172px;
  background-color: transparent !important;
}

.chart2 {
  position: relative;
  opacity: 0.4;
  width: 97vw !important;
  overflow: hidden !important;
  // margin-right: -50px;
  background-color: transparent !important;
}