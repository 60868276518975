.learning-main {
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;
  min-height: 80vh;
  background-image: url("../../assets/images/notificationbg.svg");
  width: 100%;
  background-position: top right;
  background-repeat: no-repeat;

  .learning-module {
    margin-top: 80px;
    background-color: var(--bg-colortwo);
    min-height: 80vh;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    border-radius: 15px;
    margin-bottom: 50px;
  }

  .bg-top-learning {
    background-image: var(--egbot);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 20vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    text-align: center;
  }

  .h-top-btn {
    width: 180px;
    height: 70px;
    // margin-top: 120px;
    background-color: #ffee00;
    border: 0;
    color: black;
    font-size: 18px;
  }

  .h-top-btn:hover {
    background-color: #00b8f9 !important;
  }
}


.learning-main-mobile {
  background-color: var(--bg-colorone);
  // margin-top: -50px;

  overflow: auto;
  min-height: 80vh;
  background-image: url("../../assets/images/notificationbg.svg");
  width: 100%;
  background-position: top right;
  background-repeat: no-repeat;

  .learning-module {
    margin-top: 80px;
    background-color: var(--bg-colortwo);
    min-height: 80vh;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    border-radius: 15px;
    margin-bottom: 150px;
  }

  .bg-top-learning {
    background-image: var(--egbot);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 20vh;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: white;
    text-align: center;
  }

  .h-top-btn {
    width: 180px;
    height: 70px;
    // margin-top: 120px;
    background-color: #ffee00;
    border: 0;
    color: black;
    font-size: 18px;
  }

  .h-top-btn:hover {
    background-color: #00b8f9 !important;
  }
}

.video-player {

  .modal-video {}

  .video-modalbody {}

}

.video-container {
  height: 90vh;
}