.tooltip-content2 {
  background-color: var(--tooltipbg);
  color: var(--bg-colorone);
  font-size: 14px;
  // padding: 16px;
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 35px;
  width: 250px;
  height: 133px;
  border-radius: 10px;
  cursor: pointer;
  direction: ltr !important;
  z-index: 50000001;
  box-sizing: border-box !important;
}

.total-align {
  text-align: left !important;
}

.triangle-container2 {
  border-radius: 10px;
  position: relative;
  width: 250px;
  height: 133px;
  margin-top: 125px;
  margin-right: 10px;
  z-index: 50000000;
}

.triangle-div2 {
  left: 100%;
  transform: translateY(-50%);
  width: 250px;
  height: 133px;
  background-color: var(--tooltipbg);
}

.triangle-div2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 240px;
  transform: translateY(-50%) rotate(45deg);
  width: 20px;
  height: 20px;
  background-color: var(--tooltipbg);
}

.content {
  padding: 20px;
}
