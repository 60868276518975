.profile-main {
  background-image: var(--profile);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg-colorone);
  margin-top: -30px;
  overflow: auto;
  min-height: 100vh;

  .card {
    background-color: var(--bg-colortwo) !important;
    color: var(--text-colorone) !important;

    .card-body {
      font-size: 14px !important;

      .MuiButtonBase-root,
      .MuiButton-root {
        font-size: 12px !important;
      }
    }

    .card-title {
      font-size: 16px !important;
      color: var(--orange) !important;
    }
  }

  .tab-main {
    min-height: 60vh;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: var(--bg-colorone);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    padding: 10px;
  }

  .tab-content {
    background-color: var(--bg-colorone);
    min-height: 60vh;
    border-radius: 15px;
    padding: 25px;
    border: 1px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }

  .MuiTableCell-root {
    color: var(--text-colorone) !important;
    background-color: var(--bg-colorone);
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff !important;
    --bs-nav-pills-link-active-bg: #22577a !important;
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--text-colorone) !important;
  background-color: var(--bs-colorone) !important;
  border-color: var(--orange) !important;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: 0 0;
  border: var(--bg-colorone) solid 2px !important;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  color: var(--text-colortwo);
}

.user-info {
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  border: 0px !important;
}

.userinfo-main {
  display: flex;
}

.card-title-security {
  margin-bottom: 43px !important;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #22577a !important;
  margin: 15px;
  padding: 5px;
  width: 12%;
}

.country-list {
  height: 250px;
  overflow-y: scroll;
}

.security-table {
  height: 350px !important;
}

.MuiTableContainer-root {
  background-color: var(--bg-colorone) !important;
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff !important;
  --bs-nav-pills-link-active-bg: #22577a !important;
}

.header-logs {
  color: var(--text-colorone);
}

.modal-list {
  max-height: 300px;
  overflow-y: hidden;
}

.modal-title {
  color: var(--text-colorone);
  font-size: 16px !important;
}

.modal-header {
  background-color: var(--bg-colorone);
  border-bottom: 0px !important;
  border-radius: 0px !important;
}

.modal-body {
  background-color: var(--bg-colorone);

  ul {
    li {
      color: var(--text-colorone);
    }
  }
}

.commission-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;
  color: var(--text-colorone);
  background-image: url("../../assets//images//fee-bg.svg");
  background-repeat: no-repeat;
  background-position: right top;

  .fee-title {
    background-color: #00b8f9;
    width: 100%;
    // border-radius: 15px;
    padding-bottom: 18px;
    margin-top: 25px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .dot-in-com {
    font-size: 12px !important;
    margin-left: 15px;
    color: var(--dot-color);
  }

  .copy-flex {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: var(--orange);
    }
  }

  .button-copy {
    background-color: var(--btn-one);
    color: var(--bg-colorone) !important;
    font-size: 12px;
    direction: ltr !important;
    height: 35px;

    &:hover {
      background-color: var(--btn-one-hover);
      color: var(--orange) !important;
    }
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSize10.css-821wyw-MuiSvgIcon-root {
    font-size: 16px !important;
  }

  .commission-table {
    margin-top: 100px;
    margin-bottom: 50px;
    min-height: 70vh;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    overflow: auto;

    .line {
      border-bottom: 2px solid #00b8f9;
    }

    .bot-div-com {
      border: 2px solid #00b8f9;
      padding: 30px;
      margin: 10px;
      border-radius: 15px;
      background-color: var(--bg-colorone);
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }
  }
}

.commission-main-mobile {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  // margin-top: -50px;
  overflow: auto;
  color: var(--text-colorone);
  background-image: url("../../assets//images//fee-bg.svg");
  background-repeat: no-repeat;
  background-position: right top;

  .fee-title {
    background-color: #00b8f9;
    width: 100%;
    // border-radius: 15px;
    padding-bottom: 18px;
    margin-top: 25px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .dot-in-com {
    font-size: 12px !important;
    margin-left: 15px;
    color: var(--dot-color);
  }

  .copy-flex {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: var(--orange);
    }
  }

  .button-copy {
    background-color: var(--btn-one);
    color: var(--bg-colorone) !important;
    font-size: 12px;
    direction: ltr !important;
    height: 35px;

    &:hover {
      background-color: var(--btn-one-hover);
      color: var(--orange) !important;
    }
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSize10.css-821wyw-MuiSvgIcon-root {
    font-size: 16px !important;
  }

  .commission-table {
    margin-top: 100px;
    margin-bottom: 50px;
    min-height: 70vh;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    overflow: auto;

    .line {
      border-bottom: 2px solid #00b8f9;
    }

    .bot-div-com {
      border: 2px solid #00b8f9;
      padding: 30px;
      margin: 10px;
      border-radius: 15px;
      background-color: var(--bg-colorone);
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }
  }
}

.btn-security {
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
}

.btn-security:hover {
  color: var(--orange) !important;
}

.security-main {
  Button {
    background-color: var(--bg-colorthree) !important;
  }
}

.btn-in-kyc2 {
  height: 32px;
  font-size: 12px !important;
  background-color: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
  border: 0px !important;

  &:hover {
    background-color: var(--trade3rd) !important;
    color: var(--text-colorone) !important;
    cursor: default !important;
  }
}

.btn-in-kyc3 {
  height: 32px;
  font-size: 12px !important;
  background-color: var(--tradegreen) !important;
  color: var(--bg-colorone) !important;
  border: 0px !important;

  &:hover {
    background-color: var(--trade3rd) !important;
    color: var(--text-colorone) !important;
  }
}

.kyc-information {
  width: 100%;
  min-height: 500px;
  border-radius: 15px;
  background-color: var(--bg-colorone);
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  padding: 10px;

  .table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 3rem !important;
    color: var(--text-colorone);
    vertical-align: top;
    border-color: transparent;
    font-weight: 400 !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    height: 55px !important;
  }

  p {
    color: var(--orange);
  }

  h5 {
    color: var(--tradepair);
  }
}

.text-top-margin {
  padding-top: 45px !important;
}

.modal-kyc {
  --bs-modal-width: 80% !important;
  height: 600px;

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-colorPrimary,
  .MuiInputBase-formControl,
  .MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Titillium", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: var(--text-colorone) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 45px !important;
  }

  .modal-footer {
    border-radius: 0px !important;
  }

  .modal-dialog {
    color: var(--text-colorone) !important;
    top: 100px !important;
  }

  .modal-content {
    color: var(--text-colorone) !important;
    border-radius: 0px !important;
  }

  .modal-header {
    color: var(--text-colorone) !important;
    padding: 5px !important;
  }

  .modal-body {
    color: var(--text-colorone) !important;
    padding-bottom: 1px !important;
    padding-top: 1px !important;
    height: 400px;
  }
}

.MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-colorone) !important;
}

.MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-colorone) !important;
}

.modal-dialog .modal-content {
  color: var(--text-colorone) !important;
}

.modal-sms {

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-colorPrimary,
  .MuiInputBase-formControl,
  .MuiInputBase-root-MuiOutlinedInput-root {
    font-family: "Titillium", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: var(--text-colorone) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 45px !important;
  }

  .modal-content {
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  }

  .modal-footer {
    border-radius: 0px !important;
  }
}

.cities {
  margin-top: 5px;
}

.form-select {
  -moz-padding-start: 30px !important;
  box-shadow: none !important;
  height: 45px !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-root-MuiOutlinedInput-root {
  margin-top: 5px;
  font-family: "Titillium", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: var(--text-colorone) !important;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  height: 45px !important;
}

.bank-logo {
  margin-top: -6px;
  margin-left: 5px;
}

.modaltitle {
  background-color: var(--bg-colorone);
  margin: 0;
  padding-right: 20px;
  padding-bottom: 25px;
  padding-top: 10px;
}

.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 5px;
  height: 45px !important;
  margin: 1px 0;
  padding: 2px 5px;
  background: var(--bg-colorone) !important;
  color: var(--text-colorone) !important;
  width: 100%;
  margin-top: 5px !important;
}

.btn-in-kyc4 {
  height: 40px;
  font-size: 13px !important;
  background-color: var(--tradegreen) !important;
  color: var(--bg-colorone) !important;
  border: 0px !important;

  &:hover {
    background-color: var(--trade3rd) !important;
    color: var(--text-colorone) !important;
  }
}

.btn-in-kyc5 {
  height: 40px;
  font-size: 13px !important;
  background-color: var(--tradered) !important;
  color: var(--bg-colorone) !important;
  border: 0px !important;

  &:hover {
    background-color: var(--trade3rd) !important;
    color: var(--text-colorone) !important;
  }
}

.modal-address {
  .form-select {
    -moz-padding-start: 30px !important;
    box-shadow: none !important;
    height: 45px !important;
    background-color: var(--bg-colorone) !important;
    border: 1px solid #062538;
    color: var(--text-colorone);
  }

  .form-control {
    background-color: transparent !important;
    color: var(--text-colorone) !important;
    border: 1px solid #062538;
  }

  .MuiInputBase-root,
  .MuiOutlinedInput-root,
  .MuiInputBase-colorPrimary,
  .MuiInputBase-formControl,
  .MuiInputBase-root-MuiOutlinedInput-root {
    margin-top: 5px;
    font-family: "Titillium", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: var(--text-colorone) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    height: 45px !important;
  }

  .modal-footer {
    border-radius: 0px !important;
  }

  .modal-dialog {
    top: 150px !important;
  }
}

.custom-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}

.kyc-main-page {
  background-color: var(--bg-colortwo);
  margin-top: -70px;
  background-image: url("../../assets/images/kyc-bg.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.kyc-main {
  color: var(--text-colorone);
  margin-bottom: 100px;
  margin-top: 100px !important;
}

.card-body {
  color: var(--text-colorone) !important;
  background-color: var(--bg-colorone);
  border: 0px !important;
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0) !important;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #ffffff00 !important;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bg-colorone) !important;
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: 0px !important;
}

.text-kyc {
  background-color: var(--bg-colorone);
  padding: 5px;
  border-radius: 15px;
  margin: 10px;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.user-info {

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .MuiButtonBase-root-MuiTab-root {
    color: var(--text-colorone) !important;
  }
}

.point-table {
  color: var(--text-colorone) !important;
}

.qrcode-frame {
  height: 200px;
}

.modal-pwd-changer {
  direction: ltr !important;

  .input.form-control {
    direction: ltr !important;
  }
}

.share-btn {
  background-color: var(--btn-one) !important;
  color: var(--bg-colorone) !important;
  font-size: 12px;
  direction: ltr !important;
  height: 35px;
  padding: 5px !important;
  margin-right: 15px !important;
  border-radius: 5px;
  width: 250px;
  margin-bottom: 10px;

  &:hover {
    background-color: var(--btn-one-hover) !important;
    color: var(--orange) !important;
  }
}

.card-btn {
  margin: 10px;

  .add-bank-card-btn {
    background-color: #00b8f9 !important;
    color: white !important;

    &:hover {
      background-color: #062538 !important;
    }
  }
}

.notif-kyc-card {
  color: var(--text-colorone) !important;
}

.profile-mobile-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;

  overflow: auto;
}

.profile-mobile-module {
  margin-top: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  justify-content: center;

  .btn-mobile-kyc {
    background-color: #34d100 !important;
    color: #fff !important;
    min-width: 80px;
    min-height: 50px;
    font-size: 12px;
  }

  .btn-mobile-security {
    background-color: #ff9900 !important;
    color: #fff !important;
    min-width: 80px;
    min-height: 50px;
    font-size: 12px;
  }

  .btn-mobile-income {
    background-color: #dc00f5 !important;
    color: #fff !important;
    min-width: 80px;
    min-height: 50px;
    font-size: 12px;
  }

}

.mobile-profile-list {

  .mobile-profile-list-btn {
    color: var(--text-colorone) !important;
    font-size: 14px !important;
  }
}

.security-mobile-m {
  background-color: var(--bg-colorone);
  overflow: auto;

  .security-main-mobile {
    margin-top: 50px;
    background-color: var(--bg-colorone);
    margin-bottom: 150px;

  }
}

.cardmanager-mobile {
  height: 100vh;
  background-color: var(--bg-colorone);
  overflow: auto;

  .search-main-mobile {
    margin-top: 50px;
  }
}

.bankcard-mobile-style {
  background-color: #00b8f9;
  height: 220px;
  border-radius: 15px;
  margin: 5px;
}

.delete {
  .delete-btn-card {
    background-color: rgb(255, 73, 73) !important;
    color: #fff !important;
  }
}

.transactionhistory {
  margin-top: -5px;
  overflow: auto;
  background-color: var(--bg-colorone);
  padding-bottom: 150px;
}