.terms-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .terms-module {
    margin-top: 60px;
    color: var(--text-colorone);
    text-align: justify;
    margin-bottom: 60px;
  }
}

.terms-main-mobile {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  // margin-top: -50px;
  overflow: auto;

  .terms-module {
    margin-top: 60px;
    color: var(--text-colorone);
    text-align: justify;
    margin-bottom: 160px;
  }
}