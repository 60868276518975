.deposit-both {
  background: var(--bg-digi);
  background-color: var(--bg-colorone);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -20px;
  overflow: auto;

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .MuiButtonBase-root-MuiTab-root {
    color: var(--text-colorone);
  }

  .depositModule {
    margin-top: 0px;
  }

  .MuiTabs-flexContainer {
    background-color: var(--bg-colorone);
    height: 50px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
    height: 50px;
  }

  .Mui-selected {
    color: var(--text-colortwo) !important;
    background-color: var(--orange);
  }

  .MuiTouchRipple-root {
    color: var(--text-colortwo) !important;
  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
  }

  .deposit-main {

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .MuiSelect-select {
      direction: ltr !important;
      font-family: sans-serif !important;
    }

    .MuiButtonBase-root,
    .MuiMenuItem-root,
    .MuiMenuItem-gutters,
    .MuiMenuItem-root,
    .MuiMenuItem-gutters,
    .MuiButtonBase-root-MuiMenuItem-root {
      direction: ltr !important;
      font-family: sans-serif !important;
    }

    .deposit {
      margin-top: 60px;
      min-height: 80vh;
    }

    .deposit-module {
      direction: ltr !important;
      color: var(--text-colorone);
      min-height: 600px;
      width: 700px;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .deposit-texts {
      color: var(--text-colorone) !important;
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .deposit-address {
      font-family: sans-serif !important;
      word-break: break-all;

    }
  }

  .attention-section {
    direction: ltr !important;
    font-family: sans-serif !important;
  }
}

.deposit-both-mobile {
  background: var(--bg-digi);
  background-color: var(--bg-colorone);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  // margin-top: -20px;
  overflow: auto;

  .MuiButtonBase-root,
  .MuiTab-root,
  .MuiTab-textColorPrimary,
  .MuiButtonBase-root-MuiTab-root {
    color: var(--text-colorone);
  }

  .depositModule {
    margin-top: 50px;
    margin-bottom: 150px;
  }

  .MuiTabs-flexContainer {
    background-color: var(--bg-colorone);
    height: 50px;
  }

  .MuiTabs-indicator {
    display: none;
    background: var(--text-colorone) !important;
    height: 50px;
  }

  .Mui-selected {
    color: var(--text-colortwo) !important;
    background-color: var(--orange);
  }

  .MuiTouchRipple-root {
    color: var(--text-colortwo) !important;
  }

  button.Mui-selected .Mui-root::before {
    background-color: var(--text-colorone);
  }

  .deposit-main {

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .MuiSelect-select {
      direction: ltr !important;
      font-family: sans-serif !important;
    }

    .MuiButtonBase-root,
    .MuiMenuItem-root,
    .MuiMenuItem-gutters,
    .MuiMenuItem-root,
    .MuiMenuItem-gutters,
    .MuiButtonBase-root-MuiMenuItem-root {
      direction: ltr !important;
      font-family: sans-serif !important;
    }

    .deposit {
      margin-top: 60px;
      min-height: 80vh;
    }

    .deposit-module {
      direction: ltr !important;
      color: var(--text-colorone);
      min-height: 600px;
      width: 700px;
      background-color: var(--bg-colortwo);
      border-radius: 15px;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .deposit-texts {
      color: var(--text-colorone) !important;
    }

    .MuiInputBase-root,
    .MuiSelect-icon {
      color: var(--text-colorone) !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--text-colorone) !important;
    }

    .btn-primary {
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }

    .deposit-address {
      font-family: sans-serif !important;
      word-break: break-all;

    }
  }

  .attention-section {
    direction: ltr !important;
    font-family: sans-serif !important;
  }
}

.deposit-money-main {
  min-height: 650px;
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

  h5 {
    color: var(--text-colorone);
  }

  .form-label {
    color: var(--text-colorone);
  }

  .confirm-withdraw {
    margin-top: 20px;
    background-color: var(--bluecolor) !important;
    border-color: var(--bluecolor) !important;
    color: var(--text-colorthree) !important;

    &:hover {
      background-color: var(--text-colorthree) !important;
      border-color: var(--bluecolor) !important;
      color: var(--bluecolor) !important;
    }
  }

  .form-control {
    height: 60px;
    color: var(--text-colorone) !important;
    direction: ltr;
  }

  .form-select {
    background-position: left 0.75rem center !important;
    height: 60px;
    box-shadow: none !important;
    background-color: #e4e4e4;
  }

  .fee-deposit-money {
    border: 1px solid var(--bordercl);
    border-radius: 8px;
    padding: 5px;

    h6 {
      color: var(--text-colorone);
    }
  }
}

.modal-bank-card {

  input,
  optgroup,
  select,
  textarea {
    height: 50px;
    direction: ltr;
    width: 100%;
    border-radius: 5px;
  }

  .modal-footer {
    background-color: var(--bg-colorone);
    border-top: none;
  }

  .btn-close {
    margin: calc(0.5 * var(--bs-modal-header-padding-y)) calc(15 * var(--bs-modal-header-padding-x)) calc(0.5 * var(--bs-modal-header-padding-y)) auto !important;
  }
}

.modal-header {
  border-radius: 0px !important;
}

.deposit-his-main {
  min-height: 700px;
  background-color: var(--bg-colortwo);
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  border-radius: 15px;
  padding: 30px;
}

.copy-flex {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: var(--orange);
  }
}

.copy-btn-main {
  .copy-btn {
    background-color: #00b8f9 !important;
    font-family: sans-serif !important;
    color: white !important;
  }
}

.span-txid-deposit {
  direction: ltr !important;
  font-family: sans-serif !important;
}

.loading-deposit {
  color: var(--text-colorone);
}

.deposit-money-selection {
  color: black !important;
}

input#formBasicEmail::placeholder {
  color: var(--text-colorone) !important;
  opacity: 0.61;
}

.gateway-btn {

  .goto-gateway {
    background-color: var(--orange) !important;
    color: black !important;

    &:hover {
      background-color: #00b8f9 !important;
      color: white !important;
    }
  }
}

.when-error-deposit {
  height: 100vh;
  // background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .error-module-deposit {
    margin-top: 100px;
    min-height: 300px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--bg-colortwo);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    font-size: 16px !important;

    .attention-withdraw-deposit {
      text-align: center;
      padding-top: 50px;
      font-size: 20px !important;
      color: var(--text-colorone);
    }
  }
}

.attention-withdraw-deposit span {
  color: var(--tradered) !important;
}


.when-error-deposit .error-btn-deposit .btn-withdraw-error-deposit {
  background: var(--bg-colorone) !important;
  margin-left: 0.5rem;
  color: var(--text-colorone) !important;
  font-size: 18px !important;
  font-weight: 500;
  width: 200px !important;
  border-radius: 5px;
  border: 1px var(--bg-colortwo) solid;
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

}

.navigate-btn-dmc {
  background-color: var(--orange) !important;
  color: white !important;
}

.p-color {
  color: var(--orange) !important;
}

.p-color2 {
  color: var(--text-colorone);
}

.h-color {
  color: var(--text-colorone);
  line-height: 2;
  text-align: justify;
}

.h-color2 {
  color: var(--text-colorone);
  font-size: 13px !important;
}

.attention-deposit {
  color: var(--text-colorone);
  text-align: justify;
}

.bankname-attention {
  color: #00b8f9 !important;
}

.deposit-footer-btn {
  .deposit-btn-modal {
    margin: 10px !important;
    background-color: var(--tradegreen) !important;
    color: white !important;
  }
}

.tooltip-text {
  font-size: 10 !important;
  text-align: justify;
  line-height: 2;
  color: var(--text-colorone) !important;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltip {
  background-color: var(--bg-colorthree) !important;
}

.select-in-deposit-money {
  width: 100%;
}

option.select-in-deposit-money {
  width: 500px !important;
  height: 500px;
}

.deposit-history-main-each {
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  min-height: 130px;
  color: var(--text-colorone);
  font-weight: normal;

  .success-text {
    color: #00b8f9;
    font-size: 16px;
  }

  .txid-each-mobile {
    word-break: break-all;
    font-size: 11px;

  }

  .button-copy {
    background-color: #00b8f9;
    color: white !important;
    padding: 0;
    margin: 0;
  }
}

.deposit-history-main-eachsite {
  background-color: var(--bg-colortwo);
  border-radius: 15px;
  min-height: 130px;
  // max-width: 650px;
  color: var(--text-colorone);
  font-weight: normal;

  .success-text {
    color: #00b8f9;
    font-size: 18px;
  }

  .txid-each-mobile {
    word-break: break-all;
    font-size: 14px;

  }

  .button-copy {
    background-color: #00b8f9;
    color: white !important;
    padding: 0;
    margin: 0;
  }
}