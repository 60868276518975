.about-page-main {
  min-height: 80vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;

  .about-page-module {
    margin-top: 30px;
    width: 100%;
    background-image: url("../../assets/images/about-line.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;

    .about-top {
      background-image: url("../../assets/images/about2.svg"),
        url("../../assets/images/about-top.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 80vh;
      z-index: 1;
      position: relative;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .first-title {
      color: white;
      font-size: 40px;
      padding-top: 15%;
      text-align: left;
      padding-left: 23%;
    }

    .second-title {
      color: white;
      font-size: 30px;
      padding-top: 50px;
      text-align: left;
      padding-left: 24.5%;
    }

    .third-title {
      color: white;
      font-size: 20px;
      padding-top: 50px;
      text-align: center;
      padding-right: 45%;
      // text-decoration: underline;
    }

    .second-about {
      min-height: 35vh;
      color: var(--text-colorone);
      text-align: justify;
      padding-top: 80px;
      font-size: 18px;
      line-height: 2;
      padding-bottom: 50px;
    }
  }
}

.about-page-main-mobile {
  min-height: 80vh;
  background-color: var(--bg-colorone);
  overflow: auto;


  .about-page-module {
    width: 100%;
    background-image: url("../../assets/images/about-line.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    margin-bottom: 80px;

    .about-top {
      background-image: url("../../assets/images/about2.svg"),
        url("../../assets/images/about-top.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 80vh;
      z-index: 1;
      position: relative;
      -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
      box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    }

    .first-title {
      color: white;
      font-size: 40px;
      padding-top: 15%;
      text-align: left;
      padding-left: 23%;
    }

    .second-title {
      color: white;
      font-size: 30px;
      text-align: left;
      padding-left: 24.5%;
    }

    .third-title {
      color: white;
      font-size: 20px;
      text-align: center;
      padding-right: 45%;
      margin-bottom: 50px;
      // text-decoration: underline;
    }

    .second-about {
      min-height: 35vh;
      color: var(--text-colorone);
      text-align: justify;
      font-size: 18px;
      line-height: 2;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
}