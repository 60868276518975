.login-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  background-image: url(../../assets/images/login-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  overflow: auto;


  .input-login {
    direction: ltr;
    border-radius: 5px !important;
    font-family: 'Titillium' !important;

    ::placeholder {
      color: var(--text-colorone) !important;
    }

  }

  .login-module {
    min-width: 260px;
    max-width: 400px;
    height: 520px;
    background-color: var(--bg-colortwo);

    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    margin-right: auto;
    margin-left: auto;
    margin-top: 55px;
    margin-bottom: 30px;


    p {
      color: var(--text-colorone);
      font-size: 11px;
      text-align: justify;
      padding: 15px;

      a {
        text-decoration: none;
        color: var(--orange);
      }
    }

    .login-title {
      color: var(--text-colorone);
      margin-right: auto;
      margin-left: auto;
    }

    .login-container {
      margin-top: 100px;
      max-width: 300px;
      max-height: 50px;
      margin-right: auto;
      margin-left: auto;
    }

    .login-container2 {
      margin-right: auto;
      margin-left: auto;
      margin-top: 20px;
      max-width: 300px;
      max-height: 50px;
    }

    .login-btn {
      margin-top: 20px;
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }
  }


  .forgot-pass {
    padding: 10px;

    color: var(--text-colorone);

    h6 {
      font-size: 13px;
    }
  }

  .register-btn {
    text-decoration: none;
    padding-top: 12px !important;
    // padding-bottom: 4px;
    color: antiquewhite;

    h6 {
      color: var(--text-colorone);
      padding: 0;
    }
  }
}

.login-main-mobile {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  background-image: url(../../assets/images/login-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;


  .input-login {
    direction: ltr;
    border-radius: 5px !important;
    font-family: 'Titillium' !important;

    ::placeholder {
      color: var(--text-colorone) !important;
    }

  }

  .login-module {
    min-width: 260px;
    max-width: 400px;
    height: 520px;
    background-color: var(--bg-colortwo);

    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    margin-right: auto;
    margin-left: auto;
    margin-top: 55px;
    margin-bottom: 30px;


    p {
      color: var(--text-colorone);
      font-size: 11px;
      text-align: justify;
      padding: 15px;

      a {
        text-decoration: none;
        color: var(--orange);
      }
    }

    .login-title {
      color: var(--text-colorone);
      margin-right: auto;
      margin-left: auto;
    }

    .login-container {
      margin-top: 100px;
      max-width: 300px;
      max-height: 50px;
      margin-right: auto;
      margin-left: auto;
    }

    .login-container2 {
      margin-right: auto;
      margin-left: auto;
      margin-top: 20px;
      max-width: 300px;
      max-height: 50px;
    }

    .login-btn {
      margin-top: 20px;
      background-color: var(--bluecolor);
      border-color: var(--bluecolor);
      color: var(--text-colorthree);

      &:hover {
        background-color: var(--text-colorthree);
        border-color: var(--bluecolor);
        color: var(--bluecolor);
      }
    }
  }


  .forgot-pass {
    padding: 10px;

    color: var(--text-colorone);

    h6 {
      font-size: 13px;
    }
  }

  .register-btn {
    text-decoration: none;
    padding-top: 12px !important;
    // padding-bottom: 4px;
    color: antiquewhite;

    h6 {
      color: var(--text-colorone);
      padding: 0;
    }
  }
}

.reset-pass-main {
  background-image: url(../../assets/images/loginbg.svg);
  background-color: var(--bg-colorone);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  margin-top: -50px;
  overflow: auto;
}

.reset-pass-module {
  background-color: var(--bg-colortwo);
  min-width: 400px;
  min-height: 400px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  margin-top: 100px;
  border-radius: 15px;
  color: var(--text-colorone);
  -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
}

.forgot-btn {
  height: 30px;
  color: var(--text-colorone) !important;
  padding: 0 !important;
  font-size: 13px !important;
}

.forgot-btn:hover {
  color: var(--orange) !important;
}


.btntext-otp {
  color: var(--text-colorone) !important;
  padding: 0 !important;
  font-size: 13px !important;
  border: 0px !important;
}

.btntext-otp:hover {
  color: var(--orange) !important;
}

// .input-login-codes {
//   background: white !important;
// }

.input-login-color {
  background-color: rgb(131, 131, 131) !important;
  color: black !important;
}

input,
.input-login,
.form-control {
  color: var(--text-colorone) !important;
  border-radius: 5px !important;

  &::placeholder {
    color: var(--text-colorone) !important;
    font-size: 12px !important;
  }
}

.submit-btn-resetpass {
  background-color: var(--orange) !important;
}