.fee-main {
  background-color: var(--bg-colorone);
  min-height: 80vh;
  margin-top: -80px;
  overflow: auto;
  color: var(--text-colorone) !important;

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    color: var(--text-colorone) !important;
  }

  .fees-module {
    color: var(--text-colorone) !important;
    margin-top: 100px;
    margin-bottom: 100px;

    .table-responsive {
      color: var(--text-colorone) !important;

      .table {
        color: var(--text-colorone) !important;
      }
    }
  }

  .table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    font-weight: lighter;
  }
}

.fee-main-mobile {
  background-color: var(--bg-colorone);
  min-height: 80vh;
  overflow: auto;
  color: var(--text-colorone) !important;

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    color: var(--text-colorone) !important;
  }

  .fees-module {
    color: var(--text-colorone) !important;
    margin-top: 100px;
    margin-bottom: 100px;

    .table-responsive {
      color: var(--text-colorone) !important;

      .table {
        color: var(--text-colorone) !important;
      }
    }
  }

  .table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    font-weight: lighter;
  }
}