@font-face {
  font-family: "Titillium";
  font-style: normal;
  font-weight: 200;
  src: url("../src/assets/fonts/Titillium.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium";
  font-style: normal;
  font-weight: 400;
  src: url("../src/assets/fonts/TitilliumNormal.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium";
  font-style: normal;
  font-weight: 500;
  src: url("../src/assets/fonts/TitilliumMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium";
  font-style: normal;
  font-weight: 600;
  src: url("../src/assets/fonts/TitilliumBold.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium";
  font-style: normal;
  font-weight: 700;
  src: url("../src/assets/fonts/TitilliumBlack.ttf") format("truetype");
}

@font-face {
  font-family: "Titillium0";
  font-style: normal;
  font-weight: 700;
  src: url("../src/assets/fonts/Titillium0.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Titillium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
  overflow-x: hidden;
  margin: 0 auto;
}

code {
  font-family: "Titillium", "Courier New", monospace;
}
