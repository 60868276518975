 .pwd-checker-bar {
     -webkit-appearance: none;
     appearance: none;
     width: 100%;
     height: 5px;
 }

 .pwd-checker-bar::-webkit-progress-bar {
     background-color: rgb(246, 241, 241);
     border-radius: 4px;
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     -ms-border-radius: 4px;
     -o-border-radius: 4px;
 }

 .pwd-label {
     font-size: 14px;
     color: var(--text-colorone);
     padding: 0;
     margin-bottom: 0;
     text-align: start;
     margin-right: 10px;

 }

 .pwd-checker-bar::-webkit-progress-value {
     border-radius: 4px;
     background-size: 30px 18px, 100% 100%, 100% 100%;
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     -ms-border-radius: 4px;
     -o-border-radius: 4px;
 }

 .label {
     //  margin-top: 10px;
     margin-bottom: 0px;
 }

 .strength-weak::-webkit-progress-value {
     background-color: #ff0256;
 }

 .strength-fair::-webkit-progress-value {
     background-color: #ff9d00;
 }

 .strength-good::-webkit-progress-value {
     background-color: #47aeee;
 }

 .strength-strong::-webkit-progress-value {
     background-color: #16f234;
 }

 .weak span {
     color: #ff0256;
 }

 .strength-fair span {
     color: #ff9d00;
 }

 .strength-good span {
     color: #47aeee;
 }

 .strength-strong span {
     color: #16f234;
 }