.prize-main {
    height: 1200px;
    background-color: var(--bg-colorone);
    margin-top: -50px;
    background-image: url('../../assets/images/prizebg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    // margin-bottom: 150px;

    @media (max-width: 1300px) {
        height: 1200px;
    }

    @media (max-width: 1200px) {
        height: 1830px;
    }

    @media (max-width: 767px) {
        height: 2680px;
    }

    @media (max-width: 640px) {
        height: 2840px;
    }

    .prize-top {
        margin-top: 50px;
        // min-height: 200px;
        color: var(--text-colorone);
        font-size: 18px;
        color: white;
        margin-bottom: 150px;
    }
}

.prize-main-mobile {
    height: 1200px;
    background-color: var(--bg-colorone);
    margin-top: -50px;
    background-image: url('../../assets/images/prizebg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;

    @media (max-width: 1300px) {
        height: 1200px;
    }

    @media (max-width: 1200px) {
        height: 1830px;
    }

    @media (max-width: 767px) {
        height: 2680px;
    }

    @media (max-width: 640px) {
        height: 2840px;
    }
}

.explanation-text {
    text-align: justify;

}

.prize-top {
    margin-top: 50px;
    min-height: 200px;
    color: var(--text-colorone);
    font-size: 18px;
    padding-bottom: 20px;
    color: white;
}

.right-image-prize {
    background-image: url('../../assets/images/prize.svg');
    background-position: center;
    background-size: contain;
    min-height: 350px;
    background-repeat: no-repeat;
}

.grey-box {
    background-color: rgba(255, 233, 186, 0.685);
    height: 150px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    text-align: center;
    padding-top: 35px;
    color: black;
    margin-bottom: 20px;
}

.grey-circle {
    background-color: #ffee00;
    height: 150px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    text-align: center;
    color: black;
    border-radius: 100;
}

.login-prize-btn {
    background: linear-gradient(to right, #00b8f9 50%, #ffee00 50%);
    width: 100%;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    color: rgb(29, 29, 29) !important;
}

.login-prize-btn:hover {
    background: linear-gradient(to left, #00b8f9 50%, #ffee00 50%) !important;
}

.login-prize-btn-y {
    background: #ffee00 !important;
    width: 100%;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    color: rgb(29, 29, 29) !important;
    font-size: 11px !important;
}

.login-prize-btn-y:hover {
    background-color: #fff !important;
}

.move-prize {
    margin-top: -50px;
}

.prize-text-left {
    min-height: 340px;
    margin: 0;
}

.user-prize-circle {
    background-color: #00b8f9;
    height: 170px;
    width: 170px;
    border-radius: 100px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    border: 4px solid white;
    font-size: 22px;
}

.user-prize-circle-y {
    background-color: #ffee00;
    height: 170px;
    width: 170px;
    border-radius: 100px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    border: 4px solid white;
    font-size: 22px;
    color: #00b8f9;
}

.join-prize-btn {
    background: #ffffff !important;
    width: 170px !important;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    color: rgb(29, 29, 29) !important;

}

.join-prize-btn:hover {
    background-color: #00b8f9 !important;
}

.spacer-prize {
    margin-bottom: 25px;
}

.social-icon-prize {
    color: #00b8f9;
    text-decoration: none;
}

.social-icon-prize:hover {
    color: #ffee00 !important;
}

.input-prize-social {
    direction: ltr !important;
}

.font-prize-modal {
    font-size: 13px !important;
}

.prize-h6 {
    font-size: 14px;
}

.prize-h5 {
    font-size: 15px;
}