.career-main {
  height: 90vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow: auto;
  background-image: url(../../assets/images/login-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .career-module {
    margin-top: 80px;
    min-height: 300px;
    width: 70%;
    background-color: var(--bg-colortwo);
    margin-left: auto;
    margin-right: auto;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    border-radius: 15px;
    color: var(--text-colorone);
  }

  .form-select {
    -moz-padding-start: 30px !important;
    box-shadow: none !important;
    height: 45px !important;
    background-color: var(--bg-colorone) !important;
    border: 1px solid #062538;
    color: var(--text-colorone);
  }

  .form-control {
    background-color: transparent !important;
    color: var(--text-colorone) !important;
    border: 1px solid #062538;
  }

  .bottom-career {
    background-color: var(--bg-colortwo);
    min-height: 150px;
    margin-top: 40px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    padding: 15px;
  }
}