.register-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  background-image: url(../../assets/images/login-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  overflow: auto;

  .register-module {
    max-width: 500px;
    min-height: 600px;
    background-color: var(--bg-colorone);
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-bottom: 50px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    background-color: var(--bg-colortwo);
    margin-top: 60px;

    input,
    .form-control,
    ::placeholder {
      color: var(--text-colorone) !important;
    }
  }

  .register-forms-section {
    color: var(--text-colorone);
  }

  .country-btn {
    margin-top: 15px;
    margin-bottom: 2px;
  }

  .register-title {
    color: var(--text-colorone);
  }

  .register-dir {

    .input-group,
    .form-control {
      direction: ltr !important;
      border-radius: 5px !important;
    }
  }
}

.register-main-mobile {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  background-image: url(../../assets/images/login-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;

  .register-module {
    max-width: 500px;
    min-height: 600px;
    background-color: var(--bg-colorone);
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    margin-bottom: 50px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    background-color: var(--bg-colortwo);
    margin-top: 60px;

    input,
    .form-control,
    ::placeholder {
      color: var(--text-colorone) !important;
    }
  }

  .register-forms-section {
    color: var(--text-colorone);
  }

  .country-btn {
    margin-top: 15px;
    margin-bottom: 2px;
  }

  .register-title {
    color: var(--text-colorone);
  }

  .register-dir {

    .input-group,
    .form-control {
      direction: ltr !important;
      border-radius: 5px !important;
    }
  }
}

.errors-register {
  color: var(--text-colorone);
  font-size: 13px;
  margin-top: 6px;
}