* {
  font-family: "Titillium" !important;
  outline: none !important;
  box-sizing: border-box;
  direction: rtl;
  --bs-body-bg: #55555500 !important;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  box-shadow: none !important;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  box-shadow: none !important;
}

.form-control,
.input-group>.form-floating,
.input-group>.form-select {
  box-shadow: none !important;
}

.navbar-app {
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
}

.main-app {
  min-height: 100vh;
  /* background-color: var(--bg-colorone); */
}

.modal-content {
  border-radius: 0px !important;
}

.modal-header {
  padding: 5px !important;
}

.modal-body {
  padding-bottom: 1px !important;
  padding-top: 1px !important;
}

.btn-close {
  opacity: 1 !important;
}

input[type="number"] {
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]:hover::-webkit-inner-spin-button,
input[type="number"]:hover::-webkit-outer-spin-button {
  appearance: none;
}

/* colors theme vars */

.blink {
  animation: blinker 0.3s linear infinite;
  text-shadow: rgba(255, 175, 2, 0.199) 0px 0px 2px;
  color: rgb(236, 198, 148) !important;
}

.mb-4.text-muted.card-subtitle.h6,
.mb-2.text-muted.card-subtitle.h6 {
  color: var(--text-colorone) !important;
}

:root {
  --bg-coloroneop: rgba(251, 253, 255, 0.705);
  --bg-colorone: rgb(251, 253, 255);
  --bg-colortwo: #f7f7f7;
  --bg-colorthree: #c0c0c0;
  --bg-colorfourth: #082032;
  --navbar: #fff;
  --bluecolor: #22577a;
  --bg-dropdown: rgb(231, 231, 231);
  --text-colorone: rgb(8, 8, 8);
  --text-colortwo: #272727;
  --text-colorthree: white;
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgwhite.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage.svg");
  --btn-one: #22577a;
  --btn-one-hover: white;
  --orange: #ffc107;
  --orange2: rgb(117, 117, 117);
  --orange3: rgba(255, 174, 0, 0.5);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/deposit-bg.svg");
  --bgcurve: url("./assets/images/top-curve.svg");
  --egbot: url("./assets/images/egbot.jpg");
  --audit: url("./assets/images/bg-audit.jpg");
  --trans: url("./assets/images/bg-curvy.svg");
  --profile: url("./assets/images/blue-curves.svg");
  --contact: url("./assets/images/contact-light.svg");
  --logo: url("./assets/images/TerixoLogo.svg");
  --logofooter: url("./assets/images/TerixoLogoW.svg");
  --phoneapp: url("./assets/images/phone.png");
  --tradebg: #f8f8f8;
  --trade2nd: #fcfeff;
  --trade3rd: #e4e4e4;
  --trade4th: #8d8d8d;
  --trade5th: #9b9b9b;
  --trade6th: #494949;
  --trade7th: #0089fa;
  --trade8th: #7c7c7c;
  --tradeinput: rgb(240, 241, 242);
  --tradered: #e40031;
  --tradegreen: #1eb940;
  --tradepair: #0070f0;
  --bordercl: #8383836b;
  --tradebtnbg: #49494981;
  --tooltipbg: #083049;
  --highlighter: #dbdbdb42;
  --dot-color: #00b8f9;
  --conversation-bg: rgb(189, 189, 189);
  --conversation-bg2: rgb(216, 216, 216);
  --marketinhomebg: rgba(240, 240, 240, 0.463);
  --marketinhomebtn: #00b8f9;
}

body[data-theme="light-theme"] {
  --bg-coloroneop: rgba(251, 253, 255, 0.705);
  --bg-colorone: rgb(251, 253, 255);
  --bg-colortwo: #f7f7f7;
  --bg-colorthree: #c0c0c0;
  --bg-colorfourth: #082032;
  --navbar: #fff;
  --bluecolor: #22577a;
  --bg-dropdown: rgb(231, 231, 231);
  --text-colorone: rgb(8, 8, 8);
  --text-colortwo: #272727;
  --text-colorthree: white;
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgwhite.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage.svg");
  --btn-one: #22577a;
  --btn-one-hover: white;
  --orange: #ffc107;
  --orange2: rgb(117, 117, 117);
  --orange3: rgba(255, 174, 0, 0.5);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/deposit-bg.svg");
  --bgcurve: url("./assets/images/top-curve.svg");
  --egbot: url("./assets/images/egbot.jpg");
  --audit: url("./assets/images/bg-audit.jpg");
  --trans: url("./assets/images/bg-curvy.svg");
  --profile: url("./assets/images/blue-curves.svg");
  --contact: url("./assets/images/contact-light.svg");
  --logo: url("./assets/images/TerixoLogo.svg");
  --logofooter: url("./assets/images/TerixoLogoW.svg");
  --phoneapp: url("./assets/images/phone.png");
  --tradebg: #f8f8f8;
  --trade2nd: #fcfeff;
  --trade3rd: #e4e4e4;
  --trade4th: #8d8d8d;
  --trade5th: #9b9b9b;
  --trade6th: #494949;
  --trade7th: #0089fa;
  --trade8th: #7c7c7c;
  --tradeinput: rgb(240, 241, 242);
  --tradered: #e40031;
  --tradegreen: #1eb940;
  --tradepair: #0070f0;
  --bordercl: #8383836b;
  --tradebtnbg: #49494981;
  --tooltipbg: #083049;
  --highlighter: #dbdbdb42;
  --dot-color: #00b8f9;
  --conversation-bg: rgb(189, 189, 189);
  --conversation-bg2: rgb(216, 216, 216);
  --marketinhomebg: rgba(240, 240, 240, 0.463);
  --marketinhomebtn: #00b8f9;
  --marketinhomebtntext: #ffffff;
}

body[data-theme="dark-theme"] {
  --bg-coloroneop: #333333c0;
  --bg-colorone: #464646;
  --bg-colortwo: #3b3b3b;
  --bg-colorthree: #252423;
  --bg-colorfourth: #37393a;
  --navbar: #272524;
  --bluecolor: #22211f;
  --bg-dropdown: rgb(90, 90, 90);
  --text-colorone: white;
  --text-colortwo: #272727;
  --text-colorthree: rgb(255, 255, 255);
  --text-colorfifth: white;
  --bg-homebg: url(./assets/images/bgdark.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_dark.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #40484d;
  --orange: #ffc107;
  --orange2: rgb(255, 174, 0);
  --orange3: rgba(95, 95, 95, 0.418);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/deposit-bg.svg");
  --bgcurve: url("./assets/images/top-curve-dark.svg");
  --egbot: url("./assets/images/egbotdark.jpg");
  --audit: url("./assets/images/bg-auditdark.jpg");
  --trans: url("./assets/images/bg-curvy.svg");
  --profile: url("./assets/images/blue-curves.svg");
  --contact: url("./assets/images/contact-dark.svg");
  --logo: url("./assets/images/TerixoLogo.svg");
  --logofooter: url("./assets/images/TerixoLogoW.svg");
  --phoneapp: url("./assets/images/phonedark.png");
  --tradebg: #26292c;
  --trade2nd: #1a1714;
  --trade3rd: #942424;
  --trade4th: #d1d1d1;
  --trade5th: #e2e2e2de;
  --trade6th: #ffffff;
  --trade7th: #fad400;
  --trade8th: #858585;
  --tradeinput: #202a30;
  --tradered: #e40031;
  --tradegreen: #00da6d;
  --tradepair: #00a2ff;
  --bordercl: #8383836b;
  --tradebtnbg: rgb(251, 253, 255);
  --tooltipbg: #e9f7ff;
  --highlighter: #074c8044;
  --dot-color: #f9f500;
  --conversation-bg: rgb(124, 124, 124);
  --conversation-bg2: rgb(117, 117, 117);
  --marketinhomebg: #505050;
  --marketinhomebtn: #313131;
  --marketinhomebtntext: #ffffff;
}

body[data-theme="vintage-theme"] {
  --bg-coloroneop: #eae5d9c0;
  --bg-colorone: #eae5d9;
  --bg-colortwo: #eccfd5;
  --bg-colorthree: #d2d4de;
  --bg-colorfourth: #e1cfc6;
  --navbar: #eae5d9;
  --bluecolor: #812f58;
  --bg-dropdown: #e1cfc6;
  --text-colorone: #9a2c65;
  --text-colortwo: #db4392;
  --text-colorthree: #f3d3e2;
  --text-colorfifth: rgb(112, 17, 53);
  --bg-homebg: url(./assets/images/bgvintage.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_vintage.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #812f58;
  --orange: #ffc107;
  --orange2: rgb(175, 56, 8);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/deposit-bg.svg");
  --bgcurve: url("./assets/images/top-curve-vintage.svg");
  --egbot: url("./assets/images/egbotvintage.jpg");
  --audit: url("./assets/images/bg-auditvintage.jpg");
  --trans: url("./assets/images/bg-curvy.svg");
  --profile: url("./assets/images/blue-curves.svg");
  --contact: url("./assets/images/contact-vintage.svg");
  --logo: url("./assets/images/TerixoLogo.svg");
  --logofooter: url("./assets/images/TerixoLogoW.svg");
  --tradebg: #f1d7ca;
  --trade2nd: #e1cfc6;
  --trade3rd: #e4e4e4;
  --trade4th: #500b0b;
  --trade5th: #c77272;
  --trade6th: #f85252;
  --trade7th: #f85252;
  --trade8th: #ff9898;
  --tradeinput: #f5c3bd;
  --tradered: #f5073a;
  --tradegreen: #03a755;
  --tradepair: #cc0547;
  --bordercl: #8383836b;
  --tradebtnbg: #e0e0e081;
  --highlighter: #e9a6843d;
  --marketinhomebg: rgba(240, 240, 240, 0.463);
  --marketinhomebtn: #00b8f9;
}

body[data-theme="cool-theme"] {
  --bg-coloroneop: #dfefcec0;
  --bg-colorone: #dfefce;
  --bg-colortwo: #adf1da;
  --bg-colorthree: #afecc0;
  --bg-colorfourth: #e9eca5;
  --navbar: #7ef76e;
  --bluecolor: #065f4a;
  --bg-dropdown: #e9eca5;
  --text-colorone: #0a796a;
  --text-colortwo: #21b678;
  --text-colorthree: #67b9a6;
  --text-colorfifth: rgb(11, 90, 57);
  --bg-homebg: url(./assets/images/bgcool.jpg);
  --bg-homemobile: url("./assets/images/mobile_homepage_cool.svg");
  --btn-one: #f8f8f8;
  --btn-one-hover: #0d7579;
  --orange: rgb(255, 174, 0);
  --orange: rgb(255, 174, 0);
  --orangebg: rgb(126, 126, 126);
  --bg-digi: url("./assets/images/deposit-bg.svg");
  --bgcurve: url("./assets/images/top-curve-cool.svg");
  --egbot: url("./assets/images/egbotcool.jpg");
  --audit: url("./assets/images/bg-auditcool.jpg");
  --trans: url("./assets/images/bg-curvy.svg");
  --profile: url("./assets/images/blue-curves.svg");
  --contact: url("./assets/images/contact-green.svg");
  --logo: url("./assets/images/TerixoLogo.svg");
  --logofooter: url("./assets/images/TerixoLogoW.svg");
  --tradebg: #bff3ce;
  --trade2nd: #afecc0;
  --trade3rd: #e4e4e4;
  --trade4th: #067550;
  --trade5th: #094232;
  --trade6th: #0a796a;
  --trade7th: #0a796a;
  --trade8th: #64e2d1;
  --tradeinput: #d2f5dc;
  --tradered: #e40031;
  --tradegreen: #14a138;
  --tradepair: #055786;
  --bordercl: #8383836b;
  --tradebtnbg: #eeeeeef3;
  --highlighter: #9ccca946;
  --marketinhomebg: rgba(240, 240, 240, 0.463);
  --marketinhomebtn: #00b8f9;
}

.modal-footer {
  border-radius: 0px !important;
}

.rotating-loader-lines {
  height: 100vh;
  background-color: var(--bg-colorone);
  width: 100vw;
  padding: 0 !important;
  margin-top: -20px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 170px;
  position: relative;
  margin: 0px;
  padding: 0px;
}

.P404 {
  margin-bottom: 50px;

  .h-top-btn {
    width: 210px;
    height: 70px;
    margin-top: 120px;
    background-color: #ffee00;
    border: 0;
    color: black;
    font-size: 18px;
  }

  .h-top-btn:hover {
    background-color: #00b8f9 !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}