.assets-main {
  min-height: 110vh;
  background-color: var(--bg-colorone);
  background-image: var(--trans);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -30px;
  overflow: auto;

  .text-asset-top {
    color: var(--text-colorone);
    text-align: justify;
    font-size: 12px;
  }

  .wallets {
    margin-top: 30px;
    // min-height: 70vh;
    min-width: 650px;
    background-color: var(--bg-colortwo);
    margin-bottom: 30px;
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 10px 19px -12px rgba(0, 0, 0, 0.55);

    p {
      font-weight: 500;
    }
  }

  .MuiTableCell-root {
    text-align: right !important;
    color: var(--text-colorone) !important;
  }

  .MuiButtonBase-root,
  .MuiTab-root {
    color: var(--text-colorone) !important;
  }

  .Mui-selected {
    color: var(--orange) !important;
  }
}

.MuiTableCell-root {
  background-color: var(--bg-colorone) !important;
}

.table-head {
  background-color: var(--bg-colorthree);
  color: var(--text-colorone) !important;
  font-size: 18px;
  text-align: end !important;
}

.table-row {
  color: var(--text-colorone) !important;
}

.header-text {
  color: var(--text-colorone);
  font-size: 15px !important;
  text-align: end;
}

.header-text-b {
  color: var(--text-colorone);
  font-size: 15px !important;
  font-weight: bold;
  text-align: end;
}

.divider-text {
  background-color: var(--text-colorone);
  opacity: 0.2;
}

.MuiTabs-indicator {
  display: none;
  background: var(--text-colorone) !important;
}

// .Mui-selected {
//   // color: var(--text-colorone) !important;
// }

// .MuiTouchRipple-root {
//   // color: var(--text-colortwo) !important;
// }

button.Mui-selected .Mui-root::before {
  background-color: var(--text-colorone);
}

.tab-scroll {
  overflow-y: scroll;
}

.sumofallassets {
  color: var(--text-colorone);
}

// mobile

li.nav-item {
  font-size: 12px;

  color: var(--text-colorone) !important;
}


.wallet-mobile-module {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  color: var(--text-colorone);
  overflow: auto;

}

.wallet-mobile-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  color: var(--text-colorone);
  margin-top: 70px;
  margin-bottom: 180px;

}

.nav {
  margin: 0 auto;
  padding: 0;
}

.ul.mb-3.nav.nav-tabs.nav-justified {
  justify-content: center;
  margin: 0 auto;
}

.nav-tabs {
  border: 0px !important;
  justify-content: center;

  .nav-link {
    color: var(--text-colorone) !important;
  }

}

.wallet-mobile-box {
  background-color: var(--bg-colortwo);
  height: 75px;
  border-radius: 15px;
}

.trns-btn-m {

  .transfer-btn-mobile {
    background-color: var(--orange) !important;
    color: #fff !important;
  }
}

.vip-main-mobile {
  background-color: var(--bg-colorone);
  min-height: 120vh;
  overflow-y: auto;

  .vip-module {
    color: var(--text-colorone);
    margin-top: 25px;
  }
}

.vip-main {
  background-color: var(--bg-colorone);
  min-height: 100vh;
  margin-top: -30px;
  overflow-y: auto;

  .vip-module {
    margin-top: 40px;
    color: var(--text-colorone);
  }
}

.vip-his-main {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  margin-top: -50px;
  overflow-y: auto;
  overflow-x: hidden;

  .vip-his-module {
    margin-top: 60px;
  }
}

.vip-his-main-mobile {
  min-height: 100vh;
  background-color: var(--bg-colorone);
  overflow-y: auto;
  overflow-x: hidden;

  .vip-his-module {
    margin-top: 60px;
  }
}

.p-in-history {
  color: var(--text-colorone);
}