.market-table-btn {
  width: 85px;
  height: 40px;
  background-color: var(--bluecolor) !important;
  text-align: center;
  border: 0 !important;

  .MuiTableCell-root {
    border: 0 !important;
  }


}

.market-table-btn:hover {
  background-color: var(--bg-colortwo) !important;
  color: var(--text-colorone) !important;
  font-family: 'Roboto' !important;
}

.table-head {
  background-color: var(--bg-colorthree);
  color: var(--text-colorone) !important;
  font-family: 'Roboto' !important;
}

.table-row {
  color: var(--text-colorone) !important;
  font-family: 'Roboto' !important;
}

.table-main {
  font-family: 'Roboto' !important;

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: transparent !important;
    padding: 5px !important;
    font-family: 'Roboto' !important;
  }
}

.textdanger {
  color: var(--tradered) !important;
}

.textsuccess {
  color: var(--tradegreen) !important;
}

.arrow-table-market {
  font-size: 18px !important;
}

.market-table-btn2 {
  width: 41px;
  height: 30px;
  background-color: var(--marketinhomebtn) !important;
  text-align: center;
  border: 0 !important;
  font-size: 11px !important;
  padding: 0 !important;
  color: var(--marketinhomebtntext) !important;
  margin-top: 4px;
  margin-right: -12px;


  .MuiTableCell-root {
    border: 0 !important;
  }


}

.market-table-btn2:hover {
  background-color: var(--bg-colortwo) !important;
  color: var(--text-colorone) !important;
  font-family: 'Roboto' !important;
}


.market-in-home-button-body {
  background-color: var(--marketinhomebg) !important;
  border: 0 !important;
  color: var(--text-colorone) !important;
  margin-top: 5px;
  width: 100%;
}

.top-market-home {
  color: var(--text-colorone);
  font-size: 9px;
}