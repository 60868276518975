.home-page {
  margin: 0;
  padding: 0;
  margin-top: -20px;
  position: relative;
  font-family: "Titillium" !important;
}

.home-body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: var(--bg-coloroneop);

  @media (max-width: 769px) {
    // margin-top: -390px;
  }
}

.home-top-section {
  min-height: 80vh;
  padding: 0 !important;
  margin: 0 !important;
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0;

  .top-video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.4;
    padding: 0;
    margin: 0;
    // background-color: var(--bg-colorone);
  }

  .home-top-a {
    min-height: 65vh;
    padding: 0 !important;
    margin: 0 !important;

    .h-top-right {
      margin-top: 150px;
      margin-right: 50px;
      text-align: center;
      color: var(--text-colorone);

      @media (max-width: 769px) {
        margin-right: 0px;
      }

      h1 {
        line-height: 2 !important;
        color: var(--text-colorone);
      }

      .h-top-btn {
        width: 180px;
        height: 70px;
        margin-top: 60px;
        background-color: #ffee00;
        border: 0;
        color: black;
        font-size: 18px;
      }

      .h-top-btn:hover {
        background-color: #00b8f9 !important;
      }
    }

    .h-top-left {
      margin-top: 120px;
    }
  }

  .home-top-b {
    min-height: 30vh;
    background-color: #00b8f9 !important;
    padding: 0 !important;
    display: flex;
    margin: 0 !important;
    width: 100%;
  }

  .yellow-line {
    width: 10px;
    background-color: #ffee00;
    // margin-left: 10px;
    min-height: 30vh;
    margin-right: 25px;

    @media (max-width: 840px) {
      display: none;
    }
  }

  .top3Steps {
    display: flex;
    width: 65vw;
    justify-content: center;

    @media (max-width: 840px) {
      display: none;
    }

    .boxes-top {
      display: flex;
      padding: 30px;

      .top-3-box {
        color: white;
        margin-right: -10px;

        .top-3-main {
          width: 185px;
          height: 220px;
          padding: 10px;
          border-radius: 10px;
          text-align: justify;

          h6 {
            font-weight: 700;
            padding-top: 10px;
            padding-bottom: 15px;
          }

          p {
            font-weight: normal;
          }
        }
      }

      .arrow-container {
        margin-top: 90px;
        margin-right: 40px;
      }
    }
  }

  .top3Stepssecond {
    color: #fff;
    padding: 20px;
    display: none;

    @media (max-width: 1235px) {
      display: block;
      padding: 0px;

      .arrow-container {
        margin-top: 20px;
        text-align: center;
      }

      .arrow-rotate {
        rotate: 270deg;
      }
    }
  }

  .left-slide {
    width: 30vw;
    overflow: hidden;
    margin-right: 38px;

    .left-inside {
      width: 100%;
    }

    @media (max-width: 1235px) {
      display: none;
    }
  }
}

.carousel-inside {
  width: 100%;
}

.carousel-container {
  height: 250px;

  // margin-top: 55px;
  @media (max-width: 769px) {
    height: 150px;
    display: none;
  }
}

.priceBox {
  width: 100%;
  padding: 0;
  margin: 0;

  @media (max-width: 850px) {
    margin-top: 30px;
  }
}

.market-home-page {
  min-height: 60vh;
  background-color: var(--bg-colorone);
  overflow: auto;
  // margin-top: -60px;

  @media (max-width: 1000px) {
    min-height: 30vh;
  }

  .market-home-table {
    margin: 0 auto;
    width: 100%;
    margin-top: 90px;
    margin-bottom: 100px;
    padding: 0 !important;

    .MuiTableCell-root,
    .MuiTableCell-head,
    .MuiTableCell-alignRight,
    .MuiTableCell-sizeMedium,
    .table-row,
    .MuiTableCell-root {
      font-size: 13px !important;
    }
  }

  .market-table-btn-home {
    width: 100px;
    height: 40px;
    background-color: var(--bluecolor) !important;
    text-align: center;
    border: 0 !important;
  }

  .market-table-btn-home:hover {
    background-color: var(--bg-colortwo) !important;
    color: var(--text-colorone) !important;
  }

  .table-main {
    background-color: var(--bg-colortwo) !important;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

.MuiPaper-root,
.MuiPaper-elevation,
.MuiPaper-rounded,
.MuiPaper-elevation1,
.MuiTableContainer-root,
.table-main,
.MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}

.first-middle-main {
  background-color: var(--bg-colorone);
  min-height: 50vh;

  // padding: 45px;
  .bot-mid-main {
    justify-content: center;

    .bot-mid {
      margin-left: 30px;
      margin-right: 30px;
      justify-content: center;
    }

    .btn-two {
      text-align: center;
      // margin-top: -180px;
    }

    .btn-two-style {
      width: 157px;
      height: 50px;
      background-color: #ffee00 !important;
      color: #383838 !important;
      border: 0 !important;

      &:hover {
        background-color: #00b8f9 !important;
        color: #fff !important;
      }
    }
  }
}

.application-section {
  min-height: 90vh;
  padding-top: 120px;
  background-color: var(--bg-colorone);
  color: var(--text-colorone);

  @media (max-width: 769px) {
    padding-top: 20px;
  }

  .btn-app-main {
    width: 220px !important;
    margin-left: 30px;
    margin-bottom: 100px;
    height: 35px;
    color: #383838 !important;
    padding: 0;

  }

  .appli {
    max-width: 400px;
    padding-top: 25px;
    font-size: 20px;
    padding-bottom: 25px;
  }

  .app-section {
    @media (max-width: 769px) {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
    }
  }

  .app-btns {
    display: block;
    color: #383838 !important;
    border: 0 !important;
    height: 60px;
    align-self: center;
    background-color: #f9d400 !important;
    border-radius: 05px;
    padding: 10px;
    text-decoration: none;
    line-height: 2.4;
    width: 220px !important;

    &:hover {
      background-color: #00b8f9 !important;
      color: #fff !important;
    }
  }
}

.home-3bot {
  min-height: 50vh;
  background-color: var(--bg-colorone);
  color: var(--text-colorone);
  text-align: center;
  padding-bottom: 150px;

  .home3bot-main {
    justify-content: space-between;
    text-align: center;
    padding-right: 10px;
    display: flex;

    @media (max-width: 850px) {
      flex-direction: column;
    }

    .home3bot {
      min-width: 250px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 850px) {
        width: 300px;
        margin-top: 50px;
      }
    }

    img {
      padding-bottom: 25px;
    }

    h4 {
      padding-bottom: 20px;
      text-align: right;
    }

    ul {
      text-align: right;
      font-size: 18px;
    }
  }
}

.social-main {
  min-height: 15vh;
  padding-top: 30px;
  background-color: #00b8f9;
  color: rgb(251, 253, 255);
  // padding-right: 50px;
  text-align: center;
  display: flex;
  justify-content: center;

  h3 {
    font-size: 24px !important;

    @media (max-width: 769px) {
      font-size: 18px !important;
    }
  }

  h5 {
    font-size: 20px !important;

    @media (max-width: 769px) {
      font-size: 14px !important;
    }
  }

  .social-col-main {
    display: flex;
    justify-content: center;

    @media (max-width: 769px) {}
  }

  .white-bg-social {
    width: 290px;
    text-align: end;
    background-color: white;
    height: 80px;
    margin-bottom: 50px;
    border-radius: 25px !important;
    margin-right: 160px;
    margin-bottom: -10px;

    @media only screen and (max-width: 1210px) {
      width: 320px;
      margin-bottom: 20px;
      margin-right: 0px;
      text-align: center;
    }
  }

  .social-iconstelegram {
    color: #00b8f9;
    font-size: 30px;
    margin-left: 30px;
    margin-top: 25px;

    &:hover {
      color: #f9d400;
    }

    @media only screen and (max-width: 1210px) {
      font-size: 30px;
      margin-left: 10px;
      margin-top: 25px;
    }
  }

  .social-iconsinsta {
    color: rgb(226, 0, 68);
    font-size: 30px;
    margin-left: 30px;
    margin-top: 25px;

    &:hover {
      color: #f9d400;
    }

    @media only screen and (max-width: 1210px) {
      font-size: 30px;
      margin-left: 10px;
      margin-top: 25px;
    }
  }

  .social-iconsface {
    color: #00b8f9;
    font-size: 30px;
    margin-left: 30px;
    margin-top: 25px;

    &:hover {
      color: #f9d400;
    }

    @media only screen and (max-width: 1210px) {
      font-size: 30px;
      margin-left: 10px;
      margin-top: 25px;
    }
  }

  .social-iconstwitter {
    color: #00b8f9;
    font-size: 30px;
    margin-left: 30px;
    margin-top: 25px;

    &:hover {
      color: #f9d400;
    }

    @media only screen and (max-width: 1210px) {
      font-size: 30px;
      margin-left: 10px;
      margin-top: 25px;
    }
  }

  .social-iconsyoutube {
    color: rgb(226, 0, 68);
    font-size: 30px;
    margin-left: 40px;
    margin-top: 25px;

    &:hover {
      color: #f9d400;
    }

    @media only screen and (max-width: 1210px) {
      font-size: 30px;
      margin-left: 10px;
      margin-top: 25px;
    }
  }
}

.home-q {
  @media (max-width: 700px) {
    padding-top: 25px;
  }
}

.home-q {
  background-color: var(--bg-colorone);
  min-height: 60px !important;
  overflow: auto;
  color: var(--text-colorone);



  .faq-main-home {
    @media (max-width: 1000px) {
      margin-top: -15px;
    }
  }

  .faq-module {
    background-color: transparent !important;
    min-height: 50px;
    color: var(--text-colorone) !important;
    margin-top: 14px;
    margin-bottom: 0px;
    color: var(--text-colorone);

    .accordion-button {
      background-color: transparent !important;
      color: var(--text-colorone) !important;
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      font-size: 14px !important;
      padding: 15px;
      text-align: right;
      font-family: 'Titillium' !important;

      @media (max-width: 1000px) {
        font-size: 13px !important;
        padding: 7px;
      }

      &::after {
        background-image: url('../../assets/arrow-down.svg') !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .accordion-body {
      background-color: transparent !important;
      color: var(--text-colorone) !important;
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      text-align: justify;
      font-family: 'Titillium' !important;
      font-size: 13px;

      @media (max-width: 1000px) {
        font-size: 14px !important;
      }
    }

    .accordion {
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      --bs-accordion-border-width: 0px;
      font-size: 18px !important;
    }

    .accordion-button::after {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-left: auto;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
      margin-right: 8px;
    }
  }

  .accordion {
    background-color: transparent !important;
  }

  .accordion-item {
    background-color: transparent !important;
  }
}

.home-q {
  background-color: var(--bg-colorone);

  h5 {
    color: var(--text-colorone);
  }
}

.modal-footer {
  background-color: var(--bg-colorone) !important;
  border: 0px !important;
}

.home-6-steps-main {
  background-color: var(--bg-colorone);
  width: 100%;
  padding: 0;
}

.sections-4 {
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.image-section-main {
  justify-content: center;
}

.image-4sections {
  text-align: center;
}

.phone-app {
  background-image: var(--phoneapp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 450px;
}


.blue-div {
  display: flex;
  height: 30vh;

  @media (max-width: 1024px) {
    min-height: 360px;
    margin: 0 auto;
  }

  @media (max-width: 425px) {
    min-height: 550px;
  }
}

.left-side {
  width: 30%;
  background-color: #00b8f9;
  position: relative;

  @media (max-width: 1200px) {
    display: none;
  }
}

.right-side {
  width: 70%;
  background-color: #00b8f9;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 840px) {
    display: none;
  }
}

.column {
  width: 33.33%;
  float: left;
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel img.active {
  opacity: 1;
}

.blue-div::before,
.blue-div::after {
  content: "";
  display: block;
  width: 10px;
  background-color: yellow;
  position: absolute;
  top: 0;
}

.blue-div::before {
  left: 0;
}

.blue-div::after {
  right: 0;
}

.home3-second {
  display: none;

  @media (max-width: 839px) {
    display: block;
    width: 100%;
    background-color: #00b8f9;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 20px;
    min-height: 50vh;
  }
}

.marketinhome-visibility {
  display: block;

  @media (max-width: 700px) {
    display: none;
  }
}

.marketinhome-visibility-second {
  display: none;

  @media (max-width: 699px) {
    display: block;
  }
}

.tethercolor {
  color: rgb(0, 219, 0);
}


.home-q-mobile {
  @media (max-width: 700px) {
    padding-top: 25px;
  }
}

.home-q-mobile {
  background-color: var(--bg-colorone);
  min-height: 100vh !important;
  overflow: auto;
  color: var(--text-colorone);



  .faq-main-home {
    @media (max-width: 1000px) {
      margin-top: 150px;
    }
  }

  .faq-module {
    background-color: transparent !important;
    min-height: 100vh;
    color: var(--text-colorone) !important;
    margin-bottom: 0px;
    overflow: auto;
    color: var(--text-colorone);

    .faq-mobile {
      margin-top: 50px;
    }

    .accordion-button {
      background-color: transparent !important;
      color: var(--text-colorone) !important;
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      font-size: 14px !important;
      padding: 15px;
      text-align: right;
      font-family: 'Titillium' !important;

      @media (max-width: 1000px) {
        font-size: 13px !important;
        padding: 7px;
      }

      &::after {
        background-image: url('../../assets/arrow-down.svg') !important;
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .accordion-body {
      background-color: transparent !important;
      color: var(--text-colorone) !important;
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      text-align: justify;
      font-family: 'Titillium' !important;
      font-size: 13px;

      @media (max-width: 1000px) {
        font-size: 14px !important;
      }
    }

    .accordion {
      border: 0px solid #20202000 !important;
      font-family: "Noto Sans Arabic" !important;
      box-shadow: none;
      color: var(--text-colorone) !important;
      --bs-accordion-border-width: 0px;
      font-size: 18px !important;
    }

    .accordion-button::after {
      flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-left: auto;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
      margin-right: 8px;
    }
  }

  .accordion {
    background-color: transparent !important;
  }

  .accordion-item {
    background-color: transparent !important;
  }
}

.home-q-mobile {
  background-color: var(--bg-colorone);

  h5 {
    color: var(--text-colorone);
  }
}

.h3title {
  color: transparent;
  font-size: 0 !important;
}