.slider-main {
  width: 100% !important;
  padding: 0;
  margin: 0;
  //   background-color: #00b8f9;
  color: var(--text-colorone);
  height: 50px;
  margin-bottom: 100px;
  overflow: visible !important;

  @media (max-width: 1000px) {
    margin-bottom: 15px;
  }

  .splide__track {
    overflow: hidden !important;
    position: relative;
    z-index: 0;
  }

  .textdanger {
    color: var(--tradered);
  }

  .textsuccess {
    color: var(--tradegreen);
  }
}