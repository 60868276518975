 .simplebar-track.simplebar-horizontal {
   display: none;
 }

 .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
   background-color: var(--trade4th);
   // margin-left: 10px;
 }

 .simplebar-track.simplebar-vertical {
   top: 0;
   width: 8px !important;
   // margin-right: 10px;
 }

 .open-order-main {
   overflow-x: scroll;
 }

 .textdanger {
   color: var(--tradered) !important;
 }

 .textsuccess {
   color: var(--tradegreen) !important;
 }

 #simple-tab-0,
 #simple-tab-1 {
   font-family: "Titillium" !important;

 }

 .MuiTabs-flexContainer.MuiTabs-flexContainer .MuiTypography-root {
   font-weight: 600;
 }

 .buysell-b {
   width: 300px;
 }

 /// A0

 .trade-page0 {
   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
   background-color: var(--tradebg);

   .trade-mainA0 {
     border: 1px solid var(--bordercl);
     min-height: 80vh;
     background-color: var(--trade2nd);
     margin-top: -20px;
     width: 1600px;
     margin-left: auto;
     margin-right: auto;

     .chart-a0 {
       width: 55%;
     }

     .buysell-a0 {
       width: 900px;
       margin-right: 50px;
     }

     .trade-module {
       padding-top: 15px !important;
       overflow-y: hidden;
       overflow-x: hidden;
     }

     .toppricedata-mainA {
       height: 60px;

       .pairname-top {
         color: var(--tradepair);
         font-size: 20px;
         font-weight: 600;
         margin-right: 20px;
         margin-left: 20px;
         // margin-top: 10px;
       }

       .toppricedata,
       .data24h-title {
         font-size: 12px;
         font-weight: 400;
         font-family: 'Roboto' !important;
         color: var(--orange2);
       }

       .priceirt {
         margin-top: -13px;
         font-size: 14px !important;
         font-weight: 400;
         font-family: 'Roboto' !important;
       }

       .priceusdt1 {
         font-size: 14px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
         // margin-top: 3px;
       }

       .priceusdt,
       .data24h-data {
         font-size: 14px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
         margin-top: -10px;
       }

       .change24h-data {
         color: var(--text-colorone);
         margin-top: -10px;
       }

       .h24percent {
         font-size: 14px !important;
         font-weight: 500;
         font-family: 'Roboto' !important;
         direction: ltr !important;
         text-align: end;
         margin-top: -10px;
       }
     }
   }

   .orderlist-mainA {
     min-height: 705px;

     .order-tab-sellA {
       min-height: 290px;
     }

     .MuiBox-root {
       padding-top: 0 !important;
       border: 0 !important;

       .MuiTabs-root {
         padding-right: 50px;
       }
     }

     .MuiSvgIcon-root,
     .MuiSvgIcon-fontSizeMedium,
     .MuiTab-iconWrapper,
     .MuiSvgIcon-root {
       margin-right: -2px;
       margin-left: 30px;

       font-family: "Titillium" !important;
       padding: 0;
       margin: 0;
     }

     #simple-tab-2 {
       width: 40px;
       padding: 0;
       margin-left: -35px;
     }

     #simple-tab-1 {
       width: 40px;
       padding: 0;
       margin-left: -25px;
     }

     .MuiTabs-flexContainer {
       // direction: ltr !important;
       padding-right: 0 !important;
       direction: initial;
     }

     .last-price-orderlist {
       height: 40px;
       line-height: 2.5;
       margin-right: -36px;
       // background-color: var(--tradebg);
       border-radius: 25px;
       // margin-right: 13px;
       direction: ltr !important;
       width: 350px;
     }

     .last-price-orderlist-center {
       font-size: 16px;
       font-weight: 500;
       font-family: 'Roboto' !important;
     }

     .arrow-in-orderlist {
       margin-right: 50px;
     }

     .last-price-usd-orderlist {
       font-size: 17px;
       font-weight: 500;
       color: var(--text-colorone);
     }
   }

   .order-price-in-orderlistA {
     font-size: 13px;
     font-weight: 400;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 40%;
     line-height: 1.56;
   }

   .order-amount-in-orderlistA {
     font-size: 13px;
     font-weight: 400;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlistA {
     font-size: 13px;
     font-weight: 400;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 22%;
     text-align: start;
   }

   .order-price-in-orderlist {
     font-size: 15px;
     font-weight: 500;
     text-align: start;
     width: 40%;
   }

   .order-amount-in-orderlist {
     font-size: 15px;
     font-weight: 500;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlist {
     font-size: 15px;
     font-weight: 500;
     color: var(--text-colorone);
     width: 30%;
     text-align: start;
   }

   .pairlist-mainA {
     color: var(--text-colorone);
     font-size: 15px;
     width: 365px;


     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 600;
     }

     .pair-list {
       .MuiBox-root {
         padding: 0 !important;
         border: 0 !important;
       }
     }

     .order-price-in-orderlist {
       margin-top: 5px;
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-amount-in-orderlist {
       margin-top: 5px;
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-total-in-orderlist {
       padding-right: 15px;
       margin-top: 5px;
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .star-icon {
       font-size: 15px !important;
     }
   }

   .top-title-orderlist-third {
     width: 29%;
     padding-right: 44px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-second {
     width: 30%;
     padding-right: 20px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-first {
     text-align: end;
     margin-left: -8px;
     width: 29%;
     color: var(--text-colorone);
     font-size: 13px;
     font-weight: 500;
   }

   .orders-section {
     width: 290px;
     padding: 0;
   }

   .MuiBox-root {
     padding: 0 !important;
     border: 0 !important;
   }

   .title-main {
     width: 400px;
     margin-right: 23px;
   }

   .top-title-orderlist-thirdA {
     width: 25%;
     color: var(--text-colorone);
     font-size: 13px;
     font-weight: 500;
     text-align: end;
     padding-left: 19px !important;
   }

   .top-title-orderlist-secondA {
     //  width: 20%;
     //  padding-right: 18px !important;
     color: var(--text-colorone);
     font-size: 13px;
     font-weight: 500;
   }

   .top-title-orderlist-firstA {
     text-align: end;
     // margin-left: 15px;
     width: 34%;
     color: var(--text-colorone);
     font-size: 13px;
     font-weight: 500;
   }

   .pairlist-section {
     width: 350px;
     padding: 0;
   }

   .pair-title-main {
     width: 380px;
   }

   .pair-list {
     height: 345px;
   }

   .fav-pair-list {
     height: 345px;
   }

   .spacing {
     height: 30px;
   }

   .MuiTabs-flexContainer {
     // direction: ltr !important;
     padding-right: 0 !important;
   }

   .orderhistory-mainA {
     width: 365px !important;
     color: var(--text-colorone);
   }

   .order-price-in-orderhistory {
     font-size: 13px;
     font-weight: 400;
     text-align: end;
     width: 33%;
     font-family: 'Roboto' !important;
   }

   .order-amount-in-orderhistory {
     font-size: 13px;
     text-align: end;
     width: 33%;
     font-weight: 400;
     font-family: 'Roboto' !important;
   }

   .order-time-in-orderhistory {
     font-size: 13px;
     font-weight: 400;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 30%;
   }

   .orderhistory-title {
     width: 350px;
     font-size: 15px;
     font-weight: 500;
   }

   .top-title-orderhistory-first {
     font-size: 13px;
     font-weight: 500;
     width: 31%;
     text-align: end;
   }

   .top-title-orderhistory-second {
     font-size: 13px;
     font-weight: 500;
     width: 30%;
     text-align: end;
   }

   .top-title-orderhistory-third {
     font-size: 13px;
     font-weight: 500;
     width: 30%;
     text-align: end;
   }

   .buy-sell-module-mainA {
     .input-group-text {
       display: flex;
       align-items: center;
       padding-right: 4px;
       padding-left: 4px;
       font-size: 13px;
       font-weight: 400;
       line-height: 1;
       width: 50px;
       height: 30px;
       color: var(--trade4th);
       text-align: center;
       white-space: nowrap;
       background-color: var(--tradeinput);
       border: 0px solid #ced4da;
     }

     .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
       border-bottom-right-radius: 0.375rem;
       border-top-right-radius: 0.375rem;
     }

     .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-bottom-left-radius: 0.375rem;
       border-top-left-radius: 0.375rem;
       margin-left: -1px;
       font-weight: 400;
       font-family: 'Roboto' !important;
       direction: ltr;
       font-size: 14px;
     }

     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 500;
     }

     .form-control {
       display: block;
       height: 30px;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       font-weight: 400;
       line-height: 1.5;
       color: var(--trade6th);
       background-color: var(--tradeinput);
       background-clip: padding-box;
       border: 0px solid #ced4da;
       border-bottom: 0px solid var(--tradepair);
       appearance: none;
       border-radius: 0 !important;
       transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }

     .slider-trade {
       justify-content: center;
       margin-top: 10px;
       margin-bottom: 10px;
       margin-left: 15px;
       margin-right: 15px;
     }

     .btn-limit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 12px;
     }

     .btn-limit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 12px;
     }

     .btn-market-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 33px;
     }

     .btn-market-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 33px;
     }

     .btn-stoplimit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       //  margin-top: 5px;
     }

     .btn-stoplimit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       //  margin-top: 5px;
     }
   }

   .buy-sell-module-main {
     //  width: 700px;
     min-height: 360px;
     padding: 0 !important;
     // justify-content: center;
     margin-left: auto;
     margin-right: auto;

     // .MuiButtonBase-root,
     // .MuiTab-root,
     // .MuiTab-textColorPrimary {
     //   color: var(--trade4th);
     // }

     // .Mui-selected {
     //   color: var(--orange) !important;
     // }
   }

   .buy-sell-module {
     width: 100%;
     height: 300px;
     padding: 0 !important;
     justify-content: center;
     margin-right: -22px;
   }

   .buy-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .sell-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .available-section {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     width: 100%;
     justify-content: space-between;
     color: var(--trade5th);
     font-size: 12px;
     margin-bottom: -10px;

     p {
       font-weight: 400;
       font-family: 'Roboto' !important;
     }
   }

   .market-total-value {
     color: var(--trade5th);
     font-size: 14px;
   }

   .trade-section-controller {
     width: 1700px;
     margin: 0;
     padding: 0;
     overflow-x: hidden;
   }

   .MuiTableCell-root,
   .MuiTableCell-head,
   .MuiTableCell-stickyHeader,
   .MuiTableCell-alignLeft,
   .MuiTableCell-sizeMedium,
   .MuiTableCell-roo {
     text-align: right !important;
     background-color: var(--tradebg) !important;
     color: var(--text-colorone);
     font-family: 'Roboto' !important;
   }
 }

 .trade-page {
   background-color: var(--tradebg);

   .trade-mainA {
     border: 1px solid var(--bordercl);
     min-height: 80vh;
     background-color: var(--trade2nd);
     margin-top: -20px;
     width: 1250px;
     margin-left: auto;
     margin-right: auto;

     @media screen {}

     .trade-module {
       padding-top: 15px !important;
       overflow-y: hidden;
       overflow-x: hidden;
     }

     .toppricedata-mainA {
       height: 60px;

       .pairname-top {
         color: var(--tradepair);
         font-size: 18px;
         font-weight: 600;
         margin-right: 20px;
         margin-left: 20px;
         // margin-top: 10px;
       }

       .toppricedata,
       .data24h-title {
         font-size: 12px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--orange2);
       }

       .priceirt {
         margin-top: -12px;
         font-size: 13px !important;
         font-weight: 500;
         font-family: 'Roboto' !important;
       }

       .priceusdt1 {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
         // margin-top: -15px;
       }

       .priceusdt,
       .data24h-data {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
         margin-top: -10px;
       }

       .change24h-data {
         color: var(--text-colorone);
         margin-top: -10px;
       }

       .h24percent {
         font-size: 13px !important;
         font-weight: 500;
         font-family: 'Roboto' !important;
         direction: ltr !important;
         text-align: end;
         margin-top: -10px;
       }
     }
   }

   .orderlist-mainA {
     .MuiBox-root {
       padding-top: 0 !important;
       border: 0px !important;

       .MuiTabs-root {
         padding-right: 50px;
         font-family: "Titillium" !important;
       }

       .MuiSvgIcon-root,
       .MuiSvgIcon-fontSizeMedium,
       .MuiTab-iconWrapper,
       .MuiSvgIcon-root {
         font-family: "Titillium" !important;
       }

       .MuiTabs-flexContainer {
         padding-right: 0 !important;
         direction: initial;
       }

       #simple-tab-1 {
         padding: 1px !important;
         width: 25px !important;
         min-width: 25px;
       }
     }

     .last-price-orderlist {
       height: 40px;
       line-height: 2.5;
       margin-right: 30px;
       // background-color: var(--tradebg);
       border-radius: 25px;
       margin-left: 1px;
       direction: ltr !important;
       width: 260px;
     }

     .last-price-orderlist-center {
       font-size: 16px;
       font-weight: 500;
       font-family: 'Roboto' !important;
     }

     .last-price-usd-orderlist {
       font-size: 15px;
       font-weight: 500;
       font-family: 'Roboto' !important;
       color: var(--text-colorone);
     }
   }

   .order-price-in-orderlistA {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 40%;
     line-height: 1.65;
   }

   .order-amount-in-orderlistA {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlistA {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 30%;
     text-align: start;
   }

   .order-price-in-orderlist {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: start;
     width: 40%;
   }

   .order-amount-in-orderlist {
     font-size: 13px;
     font-weight: 600;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlist {
     font-size: 13px;
     font-weight: 600;
     color: var(--text-colorone);
     width: 30%;
     text-align: start;
   }

   .pairlist-mainA {
     color: var(--text-colorone);
     font-size: 13px;
     width: 300px;

     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 500;
     }

     .pair-list {
       .MuiBox-root {
         padding: 0 !important;
         border: 0 !important;
       }
     }

     .order-price-in-orderlist {
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-amount-in-orderlist {
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-total-in-orderlist {
       padding-right: 15px;
       font-size: 13px;
       text-align: end;
       width: 30%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .star-icon {
       font-size: 15px !important;
     }
   }

   .top-title-orderlist-third {
     width: 28%;
     padding-right: 44px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-family: "Titillium" !important;
     font-weight: 500;
   }

   .top-title-orderlist-second {
     width: 30%;
     padding-right: 20px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-family: "Titillium" !important;
     font-weight: 500;
   }

   .top-title-orderlist-first {
     text-align: end;
     margin-left: -8px;
     width: 30%;
     color: var(--text-colorone);
     font-size: 13px;
     font-family: "Titillium" !important;
     font-weight: 500;
   }

   .orders-section {
     min-width: 300px;
     max-width: 300px;
     padding: 0;
   }

   .MuiBox-root {
     padding: 0 !important;
     border: 0 !important;
   }

   .title-main {
     min-width: 290px;
     max-width: 290px;
     margin-right: 23px;
   }

   .top-title-orderlist-thirdA {
     width: 33%;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: start;
     padding-left: 19px !important;
   }

   .top-title-orderlist-secondA {
     width: 33%;
     padding-right: 18px !important;
     color: var(--text-colorone);
     font-size: 13px;
   }

   .top-title-orderlist-firstA {
     text-align: end;
     // margin-left: 15px;
     width: 34%;
     color: var(--text-colorone);
     font-size: 13px;
   }

   .pairlist-section {
     width: 300px;
     padding: 0;
   }

   .pair-title-main {
     width: 300px;
   }

   .pair-list {
     height: 315px;
   }

   .fav-pair-list {
     height: 315px;
   }

   .spacing {
     height: 10px;
   }

   .MuiTabs-flexContainer {
     // direction: ltr !important;
     padding-right: 0 !important;
   }

   .orderhistory-mainA {
     width: 300px;
     color: var(--text-colorone);
   }

   .order-price-in-orderhistory {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-amount-in-orderhistory {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-time-in-orderhistory {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 30%;
   }

   .orderhistory-title {
     width: 300px;
     font-size: 13px;
     font-weight: 600;
   }

   .top-title-orderhistory-first {
     font-size: 13px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-second {
     font-size: 13px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-third {
     font-size: 13px;
     font-weight: 500;
     width: 30%;
     text-align: end;
   }

   .buy-sell-module-mainA {
     .input-group-text {
       display: flex;
       align-items: center;
       padding-right: 4px;
       padding-left: 4px;
       font-size: 13px;
       font-weight: 400;
       line-height: 1;
       width: 50px;
       height: 30px;
       color: var(--trade4th);
       text-align: center;
       white-space: nowrap;
       background-color: var(--tradeinput);
       border: 0px solid #ced4da;
     }

     .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
       border-bottom-right-radius: 0.375rem;
       border-top-right-radius: 0.375rem;
     }

     .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-bottom-left-radius: 0.375rem;
       border-top-left-radius: 0.375rem;
       margin-left: -1px;
       font-weight: 500;
       font-family: 'Roboto' !important;
       font-size: 14px;
       direction: ltr;
     }

     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 500;
     }

     .form-control {
       display: block;
       height: 30px;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       font-weight: 400;
       line-height: 1.5;
       color: var(--trade6th);
       background-color: var(--tradeinput);
       background-clip: padding-box;
       border: 0px solid #ced4da;
       border-bottom: 0px solid var(--tradepair);
       appearance: none;
       border-radius: 0 !important;
       transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }

     .slider-trade {
       justify-content: center;
       margin-top: 10px;
       margin-bottom: 10px;
       margin-left: 15px;
       margin-right: 15px;
     }

     .btn-limit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 15px;
     }

     .btn-limit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 15px;
     }

     .btn-market-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 40px;
     }

     .btn-market-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 40px;
     }

     .btn-stoplimit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 5px;
     }

     .btn-stoplimit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 5px;
     }
   }

   .buy-sell-module-main {
     width: 585px;
     min-height: 360px;
     padding: 0 !important;
     // justify-content: center;
     margin-left: auto;
     margin-right: auto;

     // .MuiButtonBase-root,
     // .MuiTab-root,
     // .MuiTab-textColorPrimary {
     //   color: var(--trade4th);
     // }

     .Mui-selected {
       color: var(--orange) !important;
     }
   }

   .buy-sell-module {
     width: 100%;
     height: 300px;
     padding: 0 !important;
     justify-content: center;
     margin-right: 3px;
   }

   .buy-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .sell-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .available-section {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     width: 100%;
     justify-content: space-between;
     color: var(--trade5th) !important;
     font-size: 10px;
     margin-bottom: -10px;

     p {
       font-weight: 500;
       font-family: 'Roboto' !important;
     }
   }

   .market-total-value {
     color: var(--trade5th);
     font-size: 13px;
   }

   .trade-section-controller {
     width: 1250px;
     margin: 0;
     padding: 0;
     overflow-x: hidden;
   }

   .MuiTableCell-root,
   .MuiTableCell-head,
   .MuiTableCell-stickyHeader,
   .MuiTableCell-alignLeft,
   .MuiTableCell-sizeMedium,
   .MuiTableCell-roo {
     text-align: right !important;
     background-color: var(--tradebg) !important;
     color: var(--text-colorone);
     font-family: 'Roboto' !important;
   }
 }

 /// B
 ///

 .trade-pageB {
   background-color: var(--tradebg);

   .trade-main {
     border: 1px solid var(--bordercl);
     min-height: 80vh;
     background-color: var(--trade2nd);
     margin-top: -20px;
     max-width: 1050px;
     margin-left: auto;
     margin-right: auto;

     @media screen {}

     .trade-module {
       padding-top: 15px !important;
       overflow-y: hidden;
       overflow-x: hidden;
     }

     .chart-b {
       margin-left: 35px;
     }

     .toppricedata-main {
       height: 50px;

       .priceirt {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         margin-top: -12px;
       }

       .priceusdt1 {
         // margin-top: -10px;
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
       }

       .pairname-top {
         color: var(--tradepair);
         font-size: 18px;
         font-weight: 700;
         margin-right: 20px;
         margin-left: 20px;
         margin-top: 10px;
       }

       .toppricedata,
       .data24h-title {
         font-size: 11px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--orange2);
       }

       .priceusdt,
       .data24h-data {
         margin-top: -10px;
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
       }

       .change24h-data {
         color: var(--text-colorone);
       }

       .h24percent {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         direction: ltr !important;
         text-align: end;
         margin-top: -10px;
       }
     }
   }

   .orderlist-main {
     .MuiBox-root {
       padding-top: 0 !important;
       border: 0 !important;

       .MuiButtonBase-root,
       .MuiTab-root,
       .MuiTab-textColorPrimary,
       .MuiButtonBase-root-MuiTab-root {
         max-width: 142px !important;
         min-width: 0px !important;
       }

       .MuiTabs-root {
         padding-right: 0px;
       }
     }

     .css-w0w6d-MuiButtonBase-root-MuiTab-root {
       max-width: 10px !important;
       min-width: 6px !important;
     }

     .MuiTabs-flexContainer {
       // direction: ltr !important;
       padding-right: 0 !important;
       direction: initial;
     }

     .last-price-orderlist {
       height: 33px;
       line-height: 2.5;
       margin-right: 20px;
       // background-color: var(--tradebg);
       border-radius: 25px;
       // margin-left: 1px;
       direction: ltr !important;
       width: 230px;
     }

     .arrow-in-orderlist {
       margin-right: 16px;
     }

     .last-price-orderlist-center {
       font-size: 15px;
       font-weight: 500;
       font-family: 'Roboto' !important;
     }

     .last-price-usd-orderlist {
       font-size: 15px;
       font-weight: 500;
       font-family: 'Roboto' !important;
       color: var(--text-colorone);
     }
   }

   .order-price-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 40%;
   }

   .order-amount-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 30%;
     text-align: end;
   }

   .order-price-in-orderlist {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: start;
     width: 40%;
   }

   .order-amount-in-orderlist {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlist {
     font-size: 13px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 30%;
     text-align: start;
   }

   .textdanger {
     color: var(--tradered);
   }

   .textsuccess {
     color: var(--tradegreen);
   }

   .pairlist-mainA {
     color: var(--text-colorone);
     font-size: 13px;
     width: 360px;
     margin-right: 100px;

     .MuiTabs-flexContainer {
       padding-right: 0 !important;
       margin-top: 10px;
     }

     .pair-list {
       .MuiBox-root {
         padding: 0 !important;
         border: 0 !important;
         width: 350px;
       }
     }

     .order-price-in-orderlist {
       font-size: 13px;
       text-align: end;
       width: 33%;
     }

     .simplebar-track.simplebar-vertical {
       top: 0;
       width: 8px;
       margin-right: 15px;
     }

     .order-amount-in-orderlist {
       font-size: 13px;
       text-align: end;
       width: 33%;
     }

     .order-total-in-orderlist {
       padding-right: 15px;
       font-size: 13px;
       text-align: end;
       width: 33%;
     }

     .star-icon {
       font-size: 15px !important;
     }
   }

   .top-title-orderlist-third {
     width: 30%;
     padding-right: 44px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-second {
     width: 30%;
     padding-right: 20px;
     color: var(--text-colorone);
     font-size: 13px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-first {
     text-align: end;
     margin-left: 5px;
     width: 30%;
     color: var(--text-colorone);
     font-size: 13px;
     font-weight: 500;
   }

   .MuiTypography-root,
   .MuiTypography-body1,
   .MuiTypography-root {
     font-size: 13px;
     font-weight: 600;
   }

   .orderlist-main {
     width: 270px;
   }

   .orders-section {
     min-width: 300px;
     max-width: 300px;
     padding: 0;
   }

   .MuiBox-root {
     padding: 0 !important;
     border: 0 !important;
   }

   .title-main {
     min-width: 290px;
     max-width: 290px;
     margin-right: 9px;
     margin-top: 5px;
   }

   .top-title-orderlist-thirdA {
     width: 35%;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
     padding-left: 19px !important;
   }

   .top-title-orderlist-secondA {
     width: 23%;
     padding-right: 3px !important;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
   }

   .top-title-orderlist-firstA {
     text-align: center;
     width: 33%;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
   }

   .pairlist-section {
     width: 300px;
     padding: 0;
   }

   .pair-title-main {
     width: 300px;
   }

   .pair-list {
     height: 350px;
   }

   .fav-pair-list {
     height: 350px;
     width: 350px;
   }

   .spacing {
     height: 30px;
   }

   .MuiTabs-flexContainer {
     padding-right: 0 !important;
   }

   .orderhistory-mainA {
     width: 350px;
     color: var(--text-colorone);

     .orderhistory-module {
       .simplebar-track.simplebar-vertical {
         top: 0;
         width: 8px;
         margin-right: 15px;
       }
     }
   }

   .order-price-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-amount-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-time-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .orderhistory-title {
     width: 300px;
     font-size: 13px;
     font-weight: 600;
     margin-top: 12px;
   }

   .top-title-orderhistory-first {
     font-size: 13px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-second {
     font-size: 13px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-third {
     font-size: 13px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .buy-sell-module-mainA {
     width: 500px;

     .input-group-text {
       display: flex;
       align-items: center;
       padding-right: 4px;
       padding-left: 4px;
       font-size: 13px;
       font-weight: 400;
       line-height: 1;
       width: 50px;
       height: 30px;
       color: var(--trade4th);
       text-align: center;
       white-space: nowrap;
       background-color: var(--tradeinput);
       border: 0px solid #ced4da;
     }

     .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
       border-bottom-right-radius: 0.375rem;
       border-top-right-radius: 0.375rem;
     }

     .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-bottom-left-radius: 0.375rem;
       border-top-left-radius: 0.375rem;
       margin-left: -1px;
       font-weight: 500;
       font-family: 'Roboto' !important;
       font-size: 13px;
       direction: ltr;
     }

     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 500;
     }

     .form-control {
       display: block;
       height: 30px;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       font-weight: 400;
       line-height: 1.5;
       color: var(--trade6th);
       background-color: var(--tradeinput);
       background-clip: padding-box;
       border: 0px solid #ced4da;
       border-bottom: 0px solid var(--tradepair);
       appearance: none;
       border-radius: 0 !important;
       transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }

     .slider-trade {
       justify-content: center;
       margin-top: 10px;
       margin-bottom: 10px;
       margin-left: 15px;
       margin-right: 15px;
     }

     .btn-limit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 39px;
     }

     .btn-limit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 39px;
     }

     .btn-market-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 40px;
     }

     .btn-market-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 40px;
     }

     .btn-stoplimit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 5px;
     }

     .btn-stoplimit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 5px;
     }
   }

   .buy-sell-module-main {
     width: 585px;
     min-height: 360px;
     padding: 0 !important;
     // justify-content: center;
     margin-left: auto;
     margin-right: auto;

     // .MuiButtonBase-root,
     // .MuiTab-root,
     // .MuiTab-textColorPrimary {
     //   color: var(--trade4th);
     // }

     // .Mui-selected {
     //   color: var(--orange) !important;
     // }
   }

   .buy-sell-module {
     width: 100%;
     height: 300px;
     padding: 0 !important;
     justify-content: center;
     margin-right: 3px;
   }

   .buy-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .sell-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .available-section {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     width: 100%;
     justify-content: space-between;
     color: var(--trade5th);
     font-size: 10px;
     margin-bottom: -10px;

     p {
       font-weight: 500;
       font-family: 'Roboto' !important;
     }
   }

   .market-total-value {
     color: var(--trade5th);
     font-size: 13px;
   }

   .trade-section-controller {
     width: 1250px;
     margin: 0;
     padding: 0;
     overflow-x: hidden;
   }

   .MuiTableCell-root,
   .MuiTableCell-head,
   .MuiTableCell-stickyHeader,
   .MuiTableCell-alignLeft,
   .MuiTableCell-sizeMedium,
   .MuiTableCell-roo {
     text-align: right !important;
     background-color: var(--tradebg) !important;
     color: var(--text-colorone);
     font-family: 'Roboto' !important;
   }

   .openorder-main {
     margin-bottom: 60px !important;
   }
 }

 /// C
 ///

 .trade-pageC {
   background-color: var(--tradebg);

   .trade-main {
     border: 1px solid var(--bordercl);
     min-height: 80vh;
     background-color: var(--trade2nd);
     margin-top: -20px;
     // max-width: 1000px;
     // margin-left: auto;
     // margin-right: auto;

     .trade-module {
       padding-top: 15px !important;
       overflow-y: hidden;
       overflow-x: hidden;
     }

     .toppricedata-main {
       height: 50px;

       .priceirt {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         margin-top: -12px;
       }

       .priceusdt1 {
         font-size: 13px;
         font-weight: 500;
         font-family: 'Roboto' !important;
       }

       .pairname-top {
         color: var(--tradepair);
         font-size: 18px;
         font-weight: 700;
         margin-right: 20px;
         margin-left: 20px;
         margin-top: 10px;
       }

       .toppricedata,
       .data24h-title {
         font-size: 10px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--orange2);
       }

       .priceusdt,
       .data24h-data {
         margin-top: -10px;
         font-size: 11px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
       }

       .change24h-data {
         color: var(--text-colorone);
       }

       .h24percent {
         font-size: 12px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         direction: ltr !important;
         text-align: end;
         margin-top: -10px;
       }
     }
   }

   .orderlist-mainA {
     width: 270px;

     .MuiButtonBase-root,
     .MuiTab-root,
     .MuiTab-textColorPrimary,
     .MuiButtonBase-root-MuiTab-root {
       max-width: 142px !important;
       min-width: 0px !important;
     }

     .MuiBox-root {
       padding-top: 0 !important;
       border: 0 !important;

       .MuiTabs-root {
         padding-right: 0px;
       }
     }

     .MuiButtonBase-root-MuiTab-root {
       max-width: 10px !important;
       min-width: 6px !important;
     }

     .MuiTabs-flexContainer {
       // direction: ltr !important;
       padding-right: 0 !important;
       direction: initial;
     }
   }

   .last-price-orderlist {
     height: 36px;
     line-height: 2.5;
     margin-right: 22px;
     // background-color: var(--tradebg);
     border-radius: 25px;
     margin-left: 1px;
     direction: ltr !important;
     width: 233px;
   }

   .last-price-orderlist-center {
     font-size: 14px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     // margin-right: 10px;
   }

   .arrow-in-orderlist {
     margin-left: 0px;
   }

   .last-price-usd-orderlist {
     font-size: 15px;
     font-weight: 600;
     color: var(--text-colorone);
   }

   .order-price-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 40%;
   }

   .order-amount-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 30%;
     text-align: end;
   }

   .order-price-in-orderlist {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: start;
     width: 40%;
   }

   .order-amount-in-orderlist {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlist {
     font-size: 12px;
     font-weight: 600;
     color: var(--text-colorone);
     width: 30%;
     text-align: start;
   }

   .textdanger {
     color: var(--tradered);
   }

   .textsuccess {
     color: var(--tradegreen);
   }

   .pairlist-mainA {
     color: var(--text-colorone);
     font-size: 12px;
     width: 290px;
     margin-right: -50px;
     margin-top: -25px;

     .MuiTabs-flexContainer {
       padding-right: 0 !important;
       margin-top: 0px;
     }

     .pair-list {
       .MuiBox-root {
         padding: 0 !important;
         border: 0 !important;
         width: 290px;
       }
     }

     .order-price-in-orderlist {
       font-size: 12px;
       text-align: end;
       width: 33%;
     }

     .simplebar-track.simplebar-vertical {
       top: 0;
       width: 8px;
       margin-right: 29px;
     }

     .order-amount-in-orderlist {
       font-size: 11px;
       text-align: end;
       width: 33%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-total-in-orderlist {
       padding-right: 15px;
       font-size: 11px;
       text-align: end;
       width: 33%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .star-icon {
       font-size: 15px !important;
     }
   }

   .top-title-orderlist-third {
     width: 30%;
     padding-right: 44px;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-second {
     width: 30%;
     padding-right: 20px;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-first {
     text-align: end;
     margin-left: 5px;
     width: 30%;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
   }

   .MuiTypography-root,
   .MuiTypography-body1,
   .MuiTypography-root {
     font-size: 13px;
     font-weight: 600;
   }

   .orders-section {
     width: 300px;
     padding: 0;
   }

   .MuiBox-root {
     padding: 0 !important;
     border: 0 !important;
   }

   .title-main {
     width: 245px;
     margin-right: 9px;
     margin-top: 5px;
   }

   .top-title-orderlist-thirdA {
     width: 37%;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     padding-left: 19px !important;
     font-weight: 500;
   }

   .top-title-orderlist-secondA {
     width: 24%;
     padding-right: 3px !important;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-firstA {
     text-align: end;
     width: 43%;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
     margin-left: -10px;
   }

   .pairlist-section {
     width: 290px;
     padding: 0;
   }

   .pair-title-main {
     width: 290px;
     margin-bottom: 3px;
     margin-top: 15px;
   }

   .pair-list {
     height: 290px;
   }

   .fav-pair-list {
     height: 350px;
     width: 290px;
   }

   .spacing {
     height: 30px;
   }

   .MuiTabs-flexContainer {
     // direction: ltr !important;
     padding-right: 0 !important;
   }

   .orderhistory-mainA {
     width: 257px;
     color: var(--text-colorone);
     margin-right: -24px;

     .simplebar-track.simplebar-vertical {
       top: 0;
       width: 8px;
       margin-right: 20px;
     }
   }

   .order-price-in-orderhistory {
     font-size: 11px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-amount-in-orderhistory {
     font-size: 11px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: center;
     width: 33%;
   }

   .order-time-in-orderhistory {
     font-size: 11px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .orderhistory-title {
     width: 300px;
     font-size: 12px;
     font-weight: 600;
     margin-top: 22px;
   }

   .top-title-orderhistory-first {
     font-size: 12px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-second {
     font-size: 12px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .top-title-orderhistory-third {
     font-size: 12px;
     font-weight: 500;
     width: 33%;
     text-align: end;
   }

   .chart-c {
     margin-left: 66px;
   }

   .buy-sell-module-mainA {
     width: 310px;
     margin-right: 15px;
     height: 410px;

     .MuiTypography-root,
     .MuiTypography-body1,
     .MuiTypography-root {
       font-size: 13px;
       font-weight: 600;
     }

     #buysell-tab-0 {
       color: var(--tradegreen) !important;
       background-color: #dbdbdb;
       border-radius: 25px;
       padding: 0 !important;
       min-width: 70px !important;
       max-width: 90px !important;
       margin-left: auto;
       margin-right: auto;
       max-height: 35px !important;
       min-height: 35px;
       border-radius: 25px;
     }

     #buysell-tab-1 {
       color: var(--tradered) !important;
       background-color: #dbdbdb;
       padding: 0 !important;
       min-width: 70px !important;
       max-width: 90px !important;
       margin-left: auto;
       margin-right: auto;
       max-height: 35px !important;
       min-height: 35px;
       border-radius: 25px;
     }

     .input-group-text {
       display: flex;
       align-items: center;
       padding-right: 4px;
       padding-left: 4px;
       font-size: 13px;
       font-weight: 400;
       line-height: 1;
       width: 50px;
       height: 30px;
       color: var(--trade4th);
       text-align: center;
       white-space: nowrap;
       background-color: var(--tradeinput);
       border: 0px solid #ced4da;
     }

     .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
       border-bottom-right-radius: 0.375rem;
       border-top-right-radius: 0.375rem;
     }

     .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-bottom-left-radius: 0.375rem;
       border-top-left-radius: 0.375rem;
       margin-left: -1px;
       font-weight: 500;
       font-family: 'Roboto' !important;
       font-size: 12px;
       direction: ltr;
     }

     .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
       font-family: "Titillium" !important;
       font-weight: 500;
     }

     .form-control {
       display: block;
       height: 30px;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       font-weight: 400;
       line-height: 1.5;
       color: var(--trade6th);
       background-color: var(--tradeinput);
       background-clip: padding-box;
       border: 0px solid #ced4da;
       border-bottom: 0px solid var(--tradepair);
       appearance: none;
       border-radius: 0 !important;
       transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }

     .slider-trade {
       justify-content: center;
       margin-top: 10px;
       margin-bottom: 10px;
       margin-left: 15px;
       margin-right: 15px;
     }

     .btn-limit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 39px;
     }

     .btn-limit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 39px;
     }

     .btn-market-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 40px;
     }

     .btn-market-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 40px;
     }

     .btn-stoplimit-sell {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradered);
       border: 0;
       margin-top: 5px;
     }

     .btn-stoplimit-buy {
       width: 100%;
       height: 35px;
       line-height: 10px;
       background-color: var(--tradegreen);
       border: 0;
       margin-top: 5px;
     }
   }

   .buy-sell-module-main {
     width: 300px;
     min-height: 360px;
     padding: 0 !important;
     // justify-content: center;
     margin-left: auto;
     margin-right: auto;

     // .MuiButtonBase-root,
     // .MuiTab-root,
     // .MuiTab-textColorPrimary {
     //   color: var(--trade4th);
     // }

     // .Mui-selected {
     //   color: var(--orange) !important;
     // }
   }

   .buy-sell-module {
     width: 100%;
     height: 300px;
     padding: 0 !important;
     justify-content: center;
     margin-right: 3px;
   }

   .buy-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .sell-side-module {
     width: 45%;
     padding-left: 5px;
     padding-right: 5px;
   }

   .available-section {
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     width: 100%;
     justify-content: space-between;
     color: var(--trade5th);
     font-size: 10px;
     margin-bottom: -10px;

     p {
       font-weight: 500;
       font-family: 'Roboto' !important;
     }
   }

   .market-total-value {
     color: var(--trade5th);
     font-size: 13px;
   }

   .trade-section-controller {
     width: 1250px;
     margin: 0;
     padding: 0;
     overflow-x: hidden;
   }

   .MuiTableCell-root,
   .MuiTableCell-head,
   .MuiTableCell-stickyHeader,
   .MuiTableCell-alignLeft,
   .MuiTableCell-sizeMedium,
   .MuiTableCell-roo {
     text-align: right !important;
     background-color: var(--tradebg) !important;
     color: var(--text-colorone);
     font-family: 'Roboto' !important;
   }

   .openorder-main {
     margin-bottom: 60px !important;
   }
 }

 /// D
 ///

 .trade-pageD {
   background-color: var(--tradebg);
   max-width: 825px;
   // min-width: 500px;
   overflow: hidden;
   margin-top: -20px;
   padding-right: 10px;
   padding-left: 10px;

   .MuiTabs-flexContainer {
     padding-right: 10 !important;
     //  margin-top: 30px;
   }

   .trade-main {
     border: 1px solid var(--bordercl);
     min-height: 80vh;
     background-color: var(--trade2nd);
     margin-top: 20px;

     .trade-module {
       padding-top: 15px !important;
       overflow-y: hidden;
       overflow-x: hidden;
       margin-right: 15px;
     }

     .toppricedata-main {
       height: 110px;

       .priceirt {
         font-size: 12px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         margin-top: -12px;
       }

       .priceusdt1 {
         font-size: 12px;
         font-weight: 500;
         font-family: 'Roboto' !important;
       }

       .pairname-top {
         color: var(--tradepair);
         font-size: 16px;
         font-weight: 700;
         margin-right: 20px;
         margin-left: 35px;
         margin-top: 10px;
         width: 100px;
       }

       .toppricedata,
       .data24h-title {
         font-size: 10px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--orange2);
       }

       .priceusdt,
       .data24h-data {
         margin-top: -10px;
         font-size: 11px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         color: var(--text-colorone);
       }

       .change24h-data {
         color: var(--text-colorone);
       }

       .h24percent {
         font-size: 12px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         direction: ltr !important;
         text-align: end;
         margin-top: -10px;
       }
     }

     .buy-sell-module-mainA {
       margin-right: 10px;
       margin-left: 10px;
       height: 385px;

       .MuiTypography-root,
       .MuiTypography-body1,
       .MuiTypography-root {
         font-size: 13px;
         font-weight: 600;
       }

       .MuiTypography-root,
       .MuiTypography-body1,
       .MuiTypography-root {
         font-size: 13px;
         font-weight: 600;
       }

       .input-group-text {
         display: flex;
         align-items: center;
         padding-right: 4px;
         padding-left: 4px;
         font-size: 13px;
         font-weight: 400;
         line-height: 1;
         width: 50px;
         height: 30px;
         color: var(--trade4th);
         text-align: center;
         white-space: nowrap;
         background-color: var(--tradeinput);
         border: 0px solid #ced4da;
       }

       .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
         border-bottom-left-radius: 0;
         border-top-left-radius: 0;
         border-bottom-right-radius: 0.375rem;
         border-top-right-radius: 0.375rem;
       }

       .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
         border-bottom-right-radius: 0;
         border-top-right-radius: 0;
         border-bottom-left-radius: 0.375rem;
         border-top-left-radius: 0.375rem;
         margin-left: -1px;
         font-weight: 500;
         font-family: 'Roboto' !important;
         font-size: 13px;
         direction: ltr;
       }

       .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
         font-family: "Titillium" !important;
         font-weight: 500;
       }

       .form-control {
         display: block;
         height: 30px;
         padding: 0.375rem 0.75rem;
         font-size: 1rem;
         font-weight: 400;
         line-height: 1.5;
         color: var(--trade6th);
         background-color: var(--tradeinput);
         background-clip: padding-box;
         border: 0px solid #ced4da;
         border-bottom: 0px solid var(--tradepair);
         appearance: none;
         border-radius: 0 !important;
         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
       }

       .slider-trade {
         justify-content: center;
         margin-top: 10px;
         margin-bottom: 10px;
         margin-left: 15px;
         margin-right: 15px;
       }

       .btn-limit-sell {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradered);
         border: 0;
         margin-top: 39px;
       }

       .btn-limit-buy {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradegreen);
         border: 0;
         margin-top: 39px;
       }

       .btn-market-sell {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradered);
         border: 0;
         margin-top: 40px;
       }

       .btn-market-buy {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradegreen);
         border: 0;
         margin-top: 40px;
       }

       .btn-stoplimit-sell {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradered);
         border: 0;
         margin-top: 5px;
       }

       .btn-stoplimit-buy {
         width: 100%;
         height: 35px;
         line-height: 10px;
         background-color: var(--tradegreen);
         border: 0;
         margin-top: 5px;
       }
     }

     .buy-sell-module-main {
       margin-left: auto;
       margin-right: auto;

       // .MuiButtonBase-root,
       // .MuiTab-root,
       // .MuiTab-textColorPrimary {
       //   color: var(--trade4th);
       // }

       // .Mui-selected {
       //   color: var(--orange) !important;
       // }
     }

     .buy-sell-module {
       width: 100%;
       height: 300px;
       padding: 0 !important;
       justify-content: center;
       margin-right: 3px;
     }

     .buy-side-module {
       // width: 45%;
       padding-left: 5px;
       padding-right: 5px;
     }

     .sell-side-module {
       // width: 45%;
       padding-left: 5px;
       padding-right: 5px;
     }

     .available-section {
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       width: 100%;
       justify-content: space-between;
       color: var(--trade5th);
       font-size: 10px;
       margin-bottom: -10px;

       p {
         font-weight: 500;
         font-family: 'Roboto' !important;
       }
     }

     .market-total-value {
       color: var(--trade5th);
       font-size: 13px;
     }

     .trade-section-controller {
       width: 1250px;
       margin: 0;
       padding: 0;
       overflow-x: hidden;
     }

     .MuiTableCell-root,
     .MuiTableCell-head,
     .MuiTableCell-stickyHeader,
     .MuiTableCell-alignLeft,
     .MuiTableCell-sizeMedium,
     .MuiTableCell-roo {
       text-align: right !important;
       background-color: var(--tradebg) !important;
       color: var(--text-colorone);
       font-family: 'Roboto' !important;
     }

     .openorder-main {
       margin-bottom: 60px !important;
     }
   }

   //////////////////
   .orderlist-mainA {
     // width: 270px;

     .MuiButtonBase-root,
     .MuiTab-root,
     .MuiTab-labelIcon,
     .MuiTab-textColorPrimary,
     .Mui-selected,
     .MuiButtonBase-root-MuiTab-root {
       // max-width: 119px !important;
       // min-width: 65px !important;
       padding: 0 !important;
     }

     .MuiTabs-flexContainer {
       justify-content: center !important;
       padding-right: 0 !important;
       direction: initial;
     }

     .MuiBox-root {
       padding-top: 0 !important;
       border: 0 !important;

       .MuiTabs-root {
         padding-right: 0px;
       }
     }
   }

   .buy-sell-module-mainD {

     .MuiTypography-root,
     .MuiTypography-body1,
     .MuiTypography-root {
       font-size: 13px;
       font-weight: 600;
     }
   }

   .last-price-orderlist {
     height: 36px;
     line-height: 2.5;
     // margin-right: 22px;
     // background-color: var(--tradebg);
     border-radius: 25px;
     // margin-left: 1px;
     direction: ltr !important;
     width: 180%;
   }

   .last-price-orderlist-center {
     font-size: 14px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     // margin-right: 20px;
   }

   .last-price-usd-orderlist {
     font-size: 15px;
     font-weight: 600;
     color: var(--text-colorone);
   }

   .order-price-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 40%;
   }

   .order-amount-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     text-align: end;
     width: 30%;
   }

   .order-total-in-orderlistA {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     color: var(--text-colorone);
     width: 15%;
     text-align: end;
   }

   .order-price-in-orderlist {
     font-size: 12px;
     font-weight: 600;
     text-align: start;
     // width: 40%;
   }

   .order-amount-in-orderlist {
     font-size: 12px;
     font-weight: 600;
     color: var(--text-colorone);
     text-align: end;
     // width: 30%;
   }

   .order-total-in-orderlist {
     font-size: 12px;
     font-weight: 600;
     color: var(--text-colorone);
     // width: 30%;
     text-align: start;
   }

   .textdanger {
     color: var(--tradered);
   }

   .textsuccess {
     color: var(--tradegreen);
   }

   .pairlist-mainA {
     color: var(--text-colorone);
     font-size: 12px;
     // width: 290px;
     margin-right: -50px;
     margin-top: -25px;

     .pair-list {
       .MuiBox-root {
         padding: 0 !important;
         border: 0 !important;
         // width: 290px;
       }
     }

     .order-price-in-orderlist {
       font-size: 12px;
       text-align: end;
       width: 33%;
     }

     .simplebar-track.simplebar-vertical {
       top: 0;
       width: 8px;
       margin-right: 29px;
     }

     .order-amount-in-orderlist {
       font-size: 12px;
       text-align: end;
       width: 33%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .order-total-in-orderlist {
       padding-right: 15px;
       font-size: 12px;
       text-align: end;
       width: 20%;
       font-family: 'Roboto' !important;
       font-weight: 500;
     }

     .star-icon {
       font-size: 15px !important;
     }
   }

   .top-title-orderlist-third {
     width: 20%;
     padding-right: 44px;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-second {
     width: 31%;
     padding-right: 20px;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-first {
     text-align: end;
     margin-left: 5px;
     width: 30%;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
   }

   .orders-section {
     // min-width: 300px;
     // max-width: 300px;
     padding: 0;
   }

   .MuiBox-root {
     padding: 0 !important;
     border: 0 !important;
   }

   .title-main {
     // width: 245px;
     margin-right: 9px;
     margin-top: 5px;
   }

   .top-title-orderlist-thirdA {
     width: 17%;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     padding-left: 19px !important;
     font-weight: 500;
   }

   .top-title-orderlist-secondA {
     width: 31%;
     padding-right: 3px !important;
     color: var(--text-colorone);
     font-size: 12px;
     text-align: end;
     font-weight: 500;
   }

   .top-title-orderlist-firstA {
     text-align: end;
     width: 43%;
     color: var(--text-colorone);
     font-size: 12px;
     font-weight: 500;
   }

   .pairlist-section {
     // width: 290px;
     padding: 0;
   }

   .pair-title-main {
     // width: 290px;
     margin-bottom: 5px;
   }

   .pair-list {
     height: 236px;
   }

   .fav-pair-list {
     height: 236px;
     // width: 290px;
   }

   .spacing {
     height: 30px;
   }

   .MuiTabs-flexContainer {
     justify-content: center !important;
     padding-right: 0 !important;
     // margin-right: 20px;
   }

   .orderhistory-mainA {
     // width: 257px;
     color: var(--text-colorone);
     margin-right: -24px;

     .simplebar-track.simplebar-vertical {
       top: 0;
       // width: 8px;
       margin-right: 20px;
     }
   }

   .order-price-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-amount-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 33%;
   }

   .order-time-in-orderhistory {
     font-size: 12px;
     font-weight: 500;
     font-family: 'Roboto' !important;
     text-align: end;
     width: 20%;
   }

   .orderhistory-title {
     // width: 300px;
     font-size: 12px;
     font-weight: 600;
     margin-top: 22px;
   }

   .top-title-orderhistory-first {
     font-size: 12px;
     font-weight: 500;
     width: 20%;
     text-align: end;
   }

   .top-title-orderhistory-second {
     font-size: 12px;
     font-weight: 500;
     width: 20%;
     text-align: end;
   }

   .top-title-orderhistory-third {
     font-size: 12px;
     font-weight: 500;
     width: 20%;
     text-align: end;
   }
 }

 .chart-d {
   .accordion-button {
     position: relative;
     display: flex;
     align-items: center;
     width: 100%;
     padding: 5px;
     font-size: 14px;
     color: var(--text-colorone);
     text-align: left;
     background-color: var(--bg-colorone);
     border: 1px solid var(--bg-colorone) !important;
     border-radius: 0;
     overflow-anchor: none;
     transition: var(--bs-accordion-transition);
     box-shadow: none !important;
   }

   .accordion-body {
     padding: 0;
   }

   .accordion {
     --bs-accordion-color: #21252900;
     --bs-accordion-bg: #ffffff00;
     --bs-accordion-transition: color 0.15s ease-in-out,
       background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
       box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
     --bs-accordion-border-color: #ffffff00;
     --bs-accordion-border-width: 1px;
     --bs-accordion-border-radius: 0.375rem;
     --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
     --bs-accordion-btn-padding-x: 1.25rem;
     --bs-accordion-btn-padding-y: 1rem;
     --bs-accordion-btn-color: #212529;
     --bs-accordion-btn-bg: var(--bs-accordion-bg);
     --bs-accordion-btn-icon: none;
     --bs-accordion-btn-icon-width: 1.25rem;
     --bs-accordion-btn-icon-transform: rotate(-180deg);
     --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
     --bs-accordion-btn-active-icon: none;
     --bs-accordion-btn-focus-border-color: #86b6fe00;
     --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
     --bs-accordion-body-padding-x: 1.25rem;
     --bs-accordion-body-padding-y: 1rem;
     --bs-accordion-active-color: #0c63e4;
     --bs-accordion-active-bg: #e7f1ff;
   }

   .accordion-button::after {
     flex-shrink: 0;
     width: var(--bs-accordion-btn-icon-width);
     height: var(--bs-accordion-btn-icon-width);
     margin-left: auto;
     content: "";
     background-image: var(--bs-accordion-btn-icon);
     background-repeat: no-repeat;
     background-size: var(--bs-accordion-btn-icon-width);
     transition: var(--bs-accordion-btn-icon-transition);
   }
 }

 /// E
 ///
 //  .trade-pageE {
 //    background-color: var(--tradebg);
 //    max-width: 550px;
 //    overflow: hidden;
 //    margin-top: -20px;
 //    padding-right: 10px;
 //    padding-left: 10px;
 //    min-width: 350px;

 //    .MuiButtonBase-root,
 //    .MuiTab-root,
 //    .MuiTab-labelIcon,
 //    .MuiTab-textColorPrimary,
 //    .Mui-selected,
 //    .MuiButtonBase-root-MuiTab-root {
 //      // max-width: 119px !important;
 //      // min-width: 65px !important;
 //      padding: 0 !important;
 //    }

 //    .MuiTabs-flexContainer {
 //      justify-content: center !important;
 //      // padding-right: 10 !important;
 //      margin-top: 10px;
 //    }

 //    .trade-main {
 //      border: 1px solid var(--bordercl);
 //      min-height: 80vh;
 //      background-color: var(--trade2nd);
 //      margin-top: 20px;

 //      .trade-module {
 //        padding-top: 15px !important;
 //        overflow-y: hidden;
 //        overflow-x: hidden;
 //        margin-right: 15px;
 //      }

 //      .toppricedata-main {
 //        height: 220px;

 //        .priceirt {
 //          font-size: 12px;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //          margin-top: -12px;
 //        }

 //        .priceusdt1 {
 //          font-size: 12px;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //        }

 //        .pairname-top {
 //          color: var(--tradepair);
 //          font-size: 16px;
 //          font-weight: 700;
 //          margin-right: 20px;
 //          margin-left: 35px;
 //          margin-top: 10px;
 //          width: 100px;
 //        }

 //        .toppricedata,
 //        .data24h-title {
 //          font-size: 11px;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //          color: var(--orange2);
 //        }

 //        .priceusdt,
 //        .data24h-data {
 //          margin-top: -10px;
 //          font-size: 13px;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //          color: var(--text-colorone);
 //        }

 //        .change24h-data {
 //          color: var(--text-colorone);
 //        }

 //        .h24percent {
 //          font-size: 13px;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //          direction: ltr !important;
 //          text-align: end;
 //          margin-top: -10px;
 //        }
 //      }

 //      .buy-sell-module-mainA {
 //        height: 420px;
 //        padding: 0;
 //        margin: 0;

 //        .MuiBox-root {
 //          padding: 1px !important;
 //          align-items: center !important;
 //          border: 0 !important;
 //        }

 //        .MuiTypography-root,
 //        .MuiTypography-body1,
 //        .MuiTypography-root {
 //          font-size: 13px;
 //          font-weight: 600;
 //        }

 //        .MuiTypography-root,
 //        .MuiTypography-body1,
 //        .MuiTypography-root {
 //          font-size: 13px;
 //          font-weight: 600;
 //        }

 //        .MuiButtonBase-root,
 //        .MuiTab-root,
 //        .MuiTab-textColorPrimary,
 //        .Mui-selected,
 //        .MuiButtonBase-root-MuiTab-root {
 //          padding: 0 !important;
 //          min-width: 70px !important;
 //          max-width: 90px !important;
 //          margin-left: auto;
 //          margin-right: auto;
 //          max-height: 35px !important;
 //          min-height: 35px;
 //          border-radius: 25px;
 //        }

 //        #buysell-tab-0 {
 //          color: var(--tradegreen) !important;
 //          background-color: #dbdbdb;
 //        }

 //        #buysell-tab-1 {
 //          color: var(--tradered) !important;
 //          background-color: #dbdbdb;
 //        }

 //        .MuiTabs-flexContainer {
 //          // direction: ltr !important;
 //          padding: 0 !important;
 //          margin-right: -20px;
 //        }

 //        .input-group-text {
 //          display: flex;
 //          align-items: center;
 //          padding-right: 4px;
 //          padding-left: 4px;
 //          font-size: 13px;
 //          font-weight: 400;
 //          line-height: 1;
 //          width: 50px;
 //          height: 30px;
 //          color: var(--trade4th);
 //          text-align: center;
 //          white-space: nowrap;
 //          background-color: var(--tradeinput);
 //          border: 0px solid #ced4da;
 //        }

 //        .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
 //          border-bottom-left-radius: 0;
 //          border-top-left-radius: 0;
 //          border-bottom-right-radius: 0.375rem;
 //          border-top-right-radius: 0.375rem;
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //          font-size: 12px;
 //          direction: ltr;
 //        }

 //        .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
 //          border-bottom-right-radius: 0;
 //          border-top-right-radius: 0;
 //          border-bottom-left-radius: 0.375rem;
 //          border-top-left-radius: 0.375rem;
 //          margin-left: -1px;
 //        }

 //        .MuiTypography-root.MuiTypography-body1.MuiTypography-root {
 //          font-family: "Titillium" !important;
 //          font-weight: 500;
 //        }

 //        .form-control {
 //          display: block;
 //          height: 30px;
 //          padding: 0.375rem 0.75rem;
 //          font-size: 1rem;
 //          font-weight: 400;
 //          line-height: 1.5;
 //          color: var(--trade6th);
 //          background-color: var(--tradeinput);
 //          background-clip: padding-box;
 //          border: 0px solid #ced4da;
 //          border-bottom: 0px solid var(--tradepair);
 //          appearance: none;
 //          border-radius: 0 !important;
 //          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 //        }

 //        .slider-trade {
 //          justify-content: center;
 //          margin-top: 10px;
 //          margin-bottom: 10px;
 //          margin-left: 15px;
 //          margin-right: 15px;
 //        }

 //        .btn-limit-sell {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradered);
 //          border: 0;
 //          margin-top: 39px;
 //        }

 //        .btn-limit-buy {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradegreen);
 //          border: 0;
 //          margin-top: 39px;
 //        }

 //        .btn-market-sell {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradered);
 //          border: 0;
 //          margin-top: 40px;
 //        }

 //        .btn-market-buy {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradegreen);
 //          border: 0;
 //          margin-top: 40px;
 //        }

 //        .btn-stoplimit-sell {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradered);
 //          border: 0;
 //          margin-top: 5px;
 //        }

 //        .btn-stoplimit-buy {
 //          width: 100%;
 //          height: 35px;
 //          line-height: 10px;
 //          background-color: var(--tradegreen);
 //          border: 0;
 //          margin-top: 5px;
 //        }
 //      }

 //      .buy-sell-module-main {
 //        margin-left: auto;
 //        margin-right: auto;

 //        .MuiTypography-root,
 //        .MuiTypography-body1,
 //        .MuiTypography-root {
 //          font-size: 13px;
 //          font-weight: 600;
 //        }
 //      }

 //      .buy-sell-module {
 //        width: 100%;
 //        height: 300px;
 //        padding: 0 !important;
 //        justify-content: center;
 //        margin-right: 3px;
 //      }

 //      .buy-side-module {
 //        // width: 45%;
 //        padding-left: 5px;
 //        padding-right: 5px;
 //      }

 //      .sell-side-module {
 //        // width: 45%;
 //        padding-left: 5px;
 //        padding-right: 5px;
 //      }

 //      .available-section {
 //        display: flex;
 //        flex-direction: row;
 //        flex-wrap: nowrap;
 //        width: 100%;
 //        justify-content: space-between;
 //        color: var(--trade5th);
 //        font-size: 10px;
 //        margin-bottom: -10px;

 //        p {
 //          font-weight: 500;
 //          font-family: 'Roboto' !important;
 //        }
 //      }

 //      .market-total-value {
 //        color: var(--trade5th);
 //        font-size: 13px;
 //      }

 //      .trade-section-controller {
 //        width: 1250px;
 //        margin: 0;
 //        padding: 0;
 //        overflow-x: hidden;
 //      }

 //      .MuiTableCell-root,
 //      .MuiTableCell-head,
 //      .MuiTableCell-stickyHeader,
 //      .MuiTableCell-alignLeft,
 //      .MuiTableCell-sizeMedium,
 //      .MuiTableCell-roo {
 //        text-align: right !important;
 //        background-color: var(--tradebg) !important;
 //        color: var(--text-colorone);
 //        font-family: 'Roboto' !important;
 //      }

 //      .openorder-main {
 //        margin-bottom: 60px !important;
 //      }
 //    }

 //    //////////////////
 //    .orderlist-mainA {

 //      // width: 270px;
 //      .MuiBox-root {
 //        padding-top: 0 !important;
 //        border: 0 !important;

 //        .MuiTabs-root {
 //          padding-right: 0px;
 //        }
 //      }
 //    }

 //    .last-price-orderlist {
 //      height: 36px;
 //      line-height: 2.7;
 //      // margin-right: 22px;
 //      // background-color: var(--tradebg);
 //      border-radius: 25px;
 //      // margin-left: 1px;
 //      direction: ltr !important;
 //      width: 150%;
 //    }

 //    .last-price-orderlist-center {
 //      font-size: 13px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      // margin-right: 20px;
 //    }

 //    // .arrow-in-orderlist {
 //    //   margin-right: 35px;
 //    // }
 //    .last-price-usd-orderlist {
 //      font-size: 13px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      color: var(--text-colorone);
 //    }

 //    .order-price-in-orderlistA {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      text-align: end;
 //      width: 40%;
 //    }

 //    .order-amount-in-orderlistA {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      color: var(--text-colorone);
 //      text-align: end;
 //      width: 30%;
 //    }

 //    .order-total-in-orderlistA {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      color: var(--text-colorone);
 //      width: 18%;
 //      text-align: end;
 //    }

 //    .order-price-in-orderlist {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      text-align: start;
 //      // width: 40%;
 //    }

 //    .order-amount-in-orderlist {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      color: var(--text-colorone);
 //      text-align: end;
 //      // width: 30%;
 //    }

 //    .order-total-in-orderlist {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      color: var(--text-colorone);
 //      // width: 30%;
 //      text-align: start;
 //    }

 //    .textdanger {
 //      color: var(--tradered);
 //    }

 //    .textsuccess {
 //      color: var(--tradegreen);
 //    }

 //    .pairlist-mainA {
 //      color: var(--text-colorone);
 //      font-size: 12px;
 //      // width: 290px;
 //      margin-right: -50px;
 //      margin-top: -25px;

 //      .MuiTabs-flexContainer {
 //        // direction: ltr !important;
 //        padding: 0 !important;
 //        margin-right: 20px;
 //        //  margin-top: 30px;
 //      }

 //      .pair-list {
 //        .MuiBox-root {
 //          padding: 0 !important;
 //          border: 0 !important;
 //          // width: 290px;
 //        }
 //      }

 //      .order-price-in-orderlist {
 //        font-size: 12px;
 //        text-align: end;
 //        width: 33%;
 //      }

 //      .simplebar-track.simplebar-vertical {
 //        top: 0;
 //        width: 8px;
 //        margin-right: 29px;
 //      }

 //      .order-amount-in-orderlist {
 //        font-size: 12px;
 //        text-align: end;
 //        width: 33%;
 //        font-family: 'Roboto' !important;
 //        font-weight: 500;
 //      }

 //      .order-total-in-orderlist {
 //        padding-right: 15px;
 //        font-size: 12px;
 //        text-align: end;
 //        width: 30%;
 //        font-family: 'Roboto' !important;
 //        font-weight: 500;
 //      }

 //      .star-icon {
 //        font-size: 15px !important;
 //      }
 //    }

 //    .buy-sell-module-mainE {

 //      .MuiTypography-root,
 //      .MuiTypography-body1,
 //      .MuiTypography-root {
 //        font-size: 10px;
 //        font-weight: 600;
 //        margin-right: 5px;
 //        margin-left: 5px;
 //      }

 //      .orderlist-mainA {
 //        .MuiTabs-flexContainer {
 //          justify-content: center !important;
 //          direction: initial;
 //          padding-right: 0 !important;
 //        }
 //      }
 //    }

 //    .top-title-orderlist-third {
 //      width: 30%;
 //      padding-right: 44px;
 //      color: var(--text-colorone);
 //      font-size: 12px;
 //      text-align: end;
 //      font-weight: 500;
 //    }

 //    .top-title-orderlist-second {
 //      width: 33%;
 //      padding-right: 20px;
 //      color: var(--text-colorone);
 //      font-size: 12px;
 //      text-align: end;
 //      font-weight: 500;
 //    }

 //    .top-title-orderlist-first {
 //      text-align: end;
 //      margin-left: 5px;
 //      width: 30%;
 //      color: var(--text-colorone);
 //      font-size: 12px;
 //      font-weight: 500;
 //    }

 //    .orders-section {
 //      // min-width: 300px;
 //      // max-width: 300px;
 //      padding: 0;
 //    }

 //    .MuiBox-root {
 //      padding: 0 !important;
 //      border: 0 !important;
 //    }

 //    .title-main {
 //      // width: 245px;
 //      margin-right: 9px;
 //      margin-top: 5px;
 //    }

 //    .top-title-orderlist-thirdA {
 //      width: 22%;
 //      color: var(--text-colorone);
 //      font-size: 10px;
 //      text-align: end;
 //      padding-left: 19px !important;
 //      font-weight: 500;
 //    }

 //    .top-title-orderlist-secondA {
 //      width: 31%;
 //      padding-right: 3px !important;
 //      color: var(--text-colorone);
 //      font-size: 10px;
 //      text-align: end;
 //      font-weight: 500;
 //    }

 //    .top-title-orderlist-firstA {
 //      text-align: end;
 //      width: 43%;
 //      color: var(--text-colorone);
 //      font-size: 10px;
 //      font-weight: 500;
 //    }

 //    .pairlist-section {
 //      // width: 290px;
 //      padding: 0;
 //    }

 //    .pair-title-main {
 //      // width: 290px;
 //      margin-bottom: 6px;
 //    }

 //    .pair-list {
 //      height: 236px;
 //    }

 //    .fav-pair-list {
 //      height: 236px;
 //      // width: 290px;
 //    }

 //    .spacing {
 //      height: 30px;
 //    }

 //    .orderhistory-mainA {
 //      // width: 257px;
 //      color: var(--text-colorone);
 //      margin-right: -24px;

 //      .simplebar-track.simplebar-vertical {
 //        top: 0;
 //        // width: 8px;
 //        margin-right: 20px;
 //      }
 //    }

 //    .order-price-in-orderhistory {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      text-align: end;
 //      width: 33%;
 //    }

 //    .order-amount-in-orderhistory {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      text-align: end;
 //      width: 33%;
 //    }

 //    .order-time-in-orderhistory {
 //      font-size: 12px;
 //      font-weight: 500;
 //      font-family: 'Roboto' !important;
 //      text-align: end;
 //      width: 28%;
 //    }

 //    .orderhistory-title {
 //      // width: 300px;
 //      font-size: 12px;
 //      font-weight: 600;
 //      margin-top: 22px;
 //    }

 //    .top-title-orderhistory-first {
 //      font-size: 12px;
 //      font-weight: 500;
 //      width: 28%;
 //      text-align: end;
 //    }

 //    .top-title-orderhistory-second {
 //      font-size: 12px;
 //      font-weight: 500;
 //      width: 25%;
 //      text-align: end;
 //    }

 //    .top-title-orderhistory-third {
 //      font-size: 12px;
 //      font-weight: 500;
 //      width: 29%;
 //      text-align: end;
 //    }
 //  }

 .MuiSlider-thumb,
 .MuiSlider-thumbSizeMedium,
 .MuiSlider-thumbColorWarning,
 .MuiSlider-thumb,
 .MuiSlider-thumbSizeMedium,
 .MuiSlider-thumbColorWarning,
 .MuiSlider-thumb {
   position: absolute;
   width: 15px !important;
   height: 15px !important;
   box-sizing: border-box;
   border-radius: 50% !important;
   outline: 0;
   background-color: transparent !important;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-align-items: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
     left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
     bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
     left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
     bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   // top: 18% !important;
   -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   border-radius: 5px;
   // transform: rotate(-45deg) !important;
   border: 5px #ff9900 solid !important;
   border-top-color: rgb(225, 86, 2);
   border-top-style: solid;
   border-top-width: 5px;
   border-right-color: rgb(225, 86, 2);
   border-right-style: solid;
   border-right-width: 5px;
   border-bottom-color: rgb(225, 86, 2);
   border-bottom-style: solid;
   border-bottom-width: 5px;
   border-left-color: rgb(225, 86, 2);
   border-left-style: solid;
   border-left-width: 5px;
   border-image-outset: 0;
   border-image-repeat: stretch;
   border-image-slice: 100%;
   border-image-source: none;
   border-image-width: 1;
   margin-left: 2px !important;
   box-shadow: none !important;
 }

 .MuiSlider-mark {
   position: absolute;
   width: 6px !important;
   height: 6px !important;
   border-radius: 10px !important;
   background-color: transparent !important;
   border: 2px solid gray !important;
   top: 50%;
   -webkit-transform: translate(-1px, -50%);
   -moz-transform: translate(-1px, -50%);
   -ms-transform: translate(-1px, -50%);
   transform: translate(-1px, -50%);
 }

 .MuiSlider-rail {
   display: block;
   position: absolute;
   border-radius: inherit;
   background-color: currentColor;
   opacity: 0.2 !important;

   height: 2px !important;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
 }

 .star-icon {
   font-size: 14px !important;
 }

 .MuiButtonBase-root,
 .MuiTab-root,
 .MuiTab-textColorPrimary,
 .MuiButtonBase-root-MuiTab-root {
   color: var(--trade8th) !important;
 }

 .Mui-selected {
   color: var(--trade7th) !important;
 }

 .MuiTypography-root {
   font-size: 14px !important;
   font-weight: 700 !important;
 }

 .top-border-onhover:hover {
   border: 1px dashed var(--text-colorone);
   box-sizing: border-box;
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   cursor: pointer;

   div {
     font-weight: 700 !important;
   }
 }

 .user-order-sell-b {
   min-height: 93px;
 }

 .user-order-sell-c {
   min-height: 87px;
 }

 .user-order-sell-d {
   min-height: 87px;
 }

 .slider-btn-holder {
   padding: 2px !important;
   margin: 0 !important;
 }

 .slider-btn-trade {
   background-color: var(--tradeinput) !important;
   min-width: 100% !important;
   width: 100%;
   height: 20px !important;
   padding: 0px !important;
   //  margin: 5px !important;
   color: var(--text-colorone) !important;
   font-family: 'Roboto' !important;
   font-size: 12px !important;
 }

 .trade-pageE .trade-main .buy-sell-module-mainA {

   .mobile-slider {
     margin: 0 auto;

     .slider-btn-trade {
       background-color: var(--tradeinput) !important;
       min-width: 100% !important;
       width: 100%;
       min-height: 18px !important;
       padding: 0px !important;
       //  margin: 5px !important;
       color: var(--text-colorone) !important;
       font-family: 'Roboto' !important;
       font-size: 12px !important;
       border-radius: 5px !important;
     }
   }
 }

 .depth-main {
   font-family: 'Titillium' !important;
   display: flex;
   flex-direction: row;
   height: 25px;
   color: white;
   justify-content: center;
   font-size: 13px;


   .sell-depth {
     background-color: rgb(179, 15, 59);
     text-align: right;
     padding: 3px;
     border-top-right-radius: 3px;
     border-bottom-right-radius: 3px;
     min-width: 15%;
   }

   .buy-depth {
     background-color: rgb(8, 169, 123);
     text-align: left;
     padding: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
     min-width: 15%;
   }

   .angled-line {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 25px;
     width: 2%;
     margin-right: -5px;
   }

   .angled-line2 {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 25px;
     width: 2%;
     margin-left: -5px;
   }
 }

 .depth-main-b {
   font-family: 'Titillium' !important;
   display: flex;
   flex-direction: row;
   height: 22px;
   color: white;
   justify-content: flex-end;
   font-size: 11px;
   width: 100%;


   .sell-depth {
     background-color: rgb(179, 15, 59);
     text-align: right;
     padding: 3px;
     border-top-right-radius: 3px;
     border-bottom-right-radius: 3px;
     min-width: 15%;
   }

   .buy-depth {
     background-color: rgb(8, 169, 123);
     text-align: left;
     padding: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
     min-width: 15%;
   }

   .angled-line {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1.5%;
     margin-right: -5px;
   }

   .angled-line2 {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1.5%;
     margin-left: -5px;
   }
 }

 .depth-main-c {
   font-family: 'Titillium' !important;
   display: flex;
   flex-direction: row;
   height: 22px;
   color: white;
   justify-content: flex-end;
   font-size: 11px;
   width: 100%;


   .sell-depth {
     background-color: rgb(179, 15, 59);
     text-align: right;
     padding: 3px;
     border-top-right-radius: 3px;
     border-bottom-right-radius: 3px;
     min-width: 15%;
   }

   .buy-depth {
     background-color: rgb(8, 169, 123);
     text-align: left;
     padding: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
     min-width: 15%;
   }

   .angled-line {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1.5%;
     margin-right: -5px;
   }

   .angled-line2 {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1.5%;
     margin-left: -5px;
   }
 }

 .depth-main-d {
   font-family: 'Titillium' !important;
   display: flex;
   flex-direction: row;
   height: 22px;
   color: white;
   justify-content: center;
   font-size: 11px;
   width: 100%;


   .sell-depth {
     background-color: rgb(179, 15, 59);
     text-align: right;
     padding: 3px;
     border-top-right-radius: 3px;
     border-bottom-right-radius: 3px;
     min-width: 15%;
   }

   .buy-depth {
     background-color: rgb(8, 169, 123);
     text-align: left;
     padding: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
     min-width: 15%;
   }

   .angled-line {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1%;
     margin-right: -5px;
   }

   .angled-line2 {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1%;
     margin-left: -5px;
   }
 }

 .depth-main-e {
   font-family: 'Titillium' !important;
   display: flex;
   flex-direction: row;
   height: 22px;
   color: white;
   justify-content: center;
   font-size: 11px;
   width: 100%;


   .sell-depth {
     background-color: rgb(179, 15, 59);
     text-align: right;
     padding: 3px;
     border-top-right-radius: 3px;
     border-bottom-right-radius: 3px;
     min-width: 15%;
   }

   .buy-depth {
     background-color: rgb(8, 169, 123);
     text-align: left;
     padding: 3px;
     border-top-left-radius: 3px;
     border-bottom-left-radius: 3px;
     min-width: 15%;
   }

   .angled-line {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1%;
     margin-right: -5px;
   }

   .angled-line2 {
     -moz-transform: skew(10deg);
     -webkit-transform: skew(10deg);
     transform: skew(10deg);
     background-color: var(--trade2nd);
     height: 22px;
     width: 1%;
     margin-left: -5px;
   }
 }

 // mobile size

 .logohere-trade {
   background-image: var(--logo);
   background-position: right;
   background-size: 100px 20px;
   background-repeat: no-repeat;
   height: 30px;
   width: 110px;
   padding: 0 !important;
   margin: 0 !important;
 }

 .trade-mobile {
   background-color: var(--tradebg);
   margin-top: -30px;
   overflow: auto;
   min-height: 100vh;
   padding: 0;

   .trade-mobile-module {
     background-color: var(--tradebg);
     margin-top: 50px;
     min-height: 100vh;
   }

   .menu-trade-mobile {

     .btn {
       background-color: transparent;
       border: 0px;
       color: var(--text-colorone);
       padding: 0;
       margin: 0;
     }

     .MuiSvgIcon-root:hover {
       color: var(--text-colorone) !important;
     }

     .dropdown-toggle::after {
       display: none;
     }

     .dropdown-menu {
       border: 0;
       padding: 0 !important;
       min-width: 30px;
       padding: 0;
       max-width: 65px;
       margin: 0;
     }

     .dropdown-item {
       display: block;
       width: 50px;
       padding: 5px;
       clear: both;
       font-weight: 400;
       color: var(--text-colorone);
       text-align: inherit;
       text-decoration: none;
       white-space: nowrap;
       background-color: transparent;
       border: 0;
       border-radius: var(--bs-dropdown-item-border-radius, 0);
       background-color: var(--bg-colorone);

     }
   }

   .mobile-trade-toppair {
     color: var(--tradepair);
     font-size: 19px;
     font-weight: 700;
     margin-right: -6px;
   }

   .mobile-lastprice {
     font-size: 17px;
     margin-top: -10px;
     font-weight: 900;
     text-align: end;
   }

   .mobile-change {
     font-size: 12px;
     margin-top: -10px;
     font-weight: 900;
     text-align: end;
   }

   .mobile-high-text {
     font-size: 9px;
     text-align: end;
     color: var(--text-colorone);
   }

   .mobile-high-number {
     font-size: 13px;
     text-align: end;
     color: var(--text-colorone);
     margin-top: -10px;
   }

   .mobile-24-text {
     font-size: 9px;
     text-align: end;
     color: var(--text-colorone);
   }

   .mobile-24-number {
     font-size: 13px;
     text-align: end;
     color: var(--text-colorone);
     margin-top: -10px;
   }

   .chart-mobile-mini {
     width: 90% !important;
     margin: 0;
     padding: 0;
     overflow: hidden !important;
     overflow-y: hidden;
   }

   .open-order-mobile-pair {
     color: var(--text-colorone);
   }

   .mobile-openorder-text {
     font-size: 11px;
     color: var(--text-colorone);
     font-weight: normal;
     margin-top: -10px;
   }

   .spacer-mobile-openorders {
     margin-top: 35px;
   }

   .mobile-cancel-btn {
     color: white !important;
   }

   .menu-pairs-mobile {

     .btn {
       background-color: transparent;
       border: 0px;
       color: var(--text-colorone);
       padding: 0;
       margin: 0;
       font-size: 12px;
     }

     .MuiSvgIcon-root:hover {
       color: var(--text-colorone) !important;
     }

     .dropdown-toggle::after {
       display: none;
     }

     .dropdown-menu {
       border: 0;
       padding: 0 !important;
       min-width: 350px;
       padding: 0;
       max-width: 65px;
       margin: 0;
     }

     .dropdown-item {
       display: block;
       width: 340px;
       padding: 5px;
       clear: both;
       font-weight: 400;
       color: var(--text-colorone);
       text-align: inherit;
       text-decoration: none;
       white-space: nowrap;
       background-color: transparent;
       border: 0;
       border-radius: var(--bs-dropdown-item-border-radius, 0);
       background-color: var(--bg-colorone);

     }
   }

   .pairlist-mobile-main {
     .MuiBox-root {
       padding: 0 !important;
       margin: 0 !important;
     }

     .mobile-orderlist-third {
       font-size: 12px;
       font-weight: normal;

     }

     .mobile-orderlist-second {
       font-size: 12px;
       font-weight: normal;
     }

     .mobile-orderlist-first {
       font-size: 12px;
       font-weight: normal;
     }

     .eachpair-mobile-vol {
       font-size: 13px;
       font-weight: normal;
       text-align: end;
     }

     .eachpair-mobile-amount {
       font-size: 13px;
       font-weight: normal;
       text-align: end;
     }

     .eachpair-mobile-price {
       font-size: 13px;
       font-weight: normal;
       text-align: end;
     }

   }

   .orderlist-mobile {
     margin-top: -35px;

     .MuiBox-root {
       padding: 5px !important;
       margin: 0 auto !important;
     }

   }


 }

 .mobile-last-third {
   font-size: 12px;
   font-weight: normal;
   margin-right: -30px;
 }

 .mobile-last-second {
   font-size: 12px;
   font-weight: normal;
 }

 .mobile-last-first {
   font-size: 12px;
   font-weight: normal;
 }

 .order-total-in-mobile {
   font-size: 12px;
 }

 .order-amount-in-mobile {
   font-size: 12px;
 }

 .order-price-in-mobile {
   font-size: 12px;
 }

 .mobile-lastprice-orders {
   font-size: 14px !important;
   display: flex;
   justify-content: flex-end;
   margin-top: 10px;
 }

 .top-title-orderhistory-thirdmobile {
   font-size: 13px;
   margin-right: 25px;
 }

 .top-title-orderhistory-secondmobile {
   font-size: 13px;
   margin-right: 75px;
 }

 .top-title-orderhistory-firstmobile {
   font-size: 13px;
   margin-left: -19px;
 }

 .order-time-in-orderhistory-mobile {
   font-size: 14px;
   margin-right: 15px;
   text-align: start;
 }

 .order-amount-in-orderhistory-mobile {
   font-size: 14px;
   text-align: end;
 }

 .order-price-in-orderhistory-mobile {
   font-size: 14px;
 }

 .buysell-opener-mobile {
   width: 100%;
   position: fixed;
   bottom: 0px;
   text-align: center;

   .mobile-btn-buysell-opener {
     background: linear-gradient(to right, rgb(255, 0, 81) 50%, rgb(0, 216, 0) 50%);
     color: #ffffff !important;
     /* Text color */
   }


   .buy-sell-mobile-overlay {
     background-color: #212529ba;
     height: 100vh;
     position: fixed;
     width: 100%;
     padding: 0;
     margin: 0;
     z-index: 1;
     bottom: 0;
   }

 }

 .mobile-buysell-close {
   text-align: start;

   .mobile-btn-buysell-closer {
     background-color: #ff2600 !important;
     color: #fff !important;
     min-width: 25px !important;
     min-height: 32px !important;
     height: 32px;
     position: relative;
     z-index: 10;
   }

 }

 .buy-sell-mobile-module {
   background-color: var(--trade2nd);
   position: relative;
   z-index: 10;
 }

 .buy-sell-module-mobile {


   .limit-buy-main {
     .input-group-text {
       display: flex;
       align-items: center;
       padding-right: 4px;
       padding-left: 4px;
       font-size: 13px;
       font-weight: 400;
       line-height: 1;
       width: 50px;
       height: 30px;
       color: var(--trade4th);
       text-align: center;
       white-space: nowrap;
       background-color: var(--tradeinput);
       border: 0px solid #ced4da;
     }

     .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
       border-bottom-left-radius: 0;
       border-top-left-radius: 0;
       border-bottom-right-radius: 0.375rem;
       border-top-right-radius: 0.375rem;
     }

     .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
       border-bottom-right-radius: 0;
       border-top-right-radius: 0;
       border-bottom-left-radius: 0.375rem;
       border-top-left-radius: 0.375rem;
       margin-left: -1px;
       font-weight: 400;
       font-family: 'Roboto' !important;
       direction: ltr;
       font-size: 14px;
     }

     .form-control {
       display: block;
       height: 30px;
       padding: 0.375rem 0.75rem;
       font-size: 1rem;
       font-weight: 400;
       line-height: 1.5;
       color: var(--trade6th);
       background-color: var(--tradeinput);
       background-clip: padding-box;
       border: 0px solid #ced4da;
       border-bottom: 0px solid var(--tradepair);
       appearance: none;
       border-radius: 0 !important;
       transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     }

     .available-section {
       color: var(--text-colorone);
       font-size: 12px;
       display: flex;
       justify-content: space-between;
     }

     .market-total-value {
       color: var(--text-colorone);
       font-size: 12px;
     }
   }

   button#buysell-mobile-0 {
     padding: 0;
     margin: 0;
   }

   button#buysell-mobile-1 {
     padding: 0;
     margin: 0;
   }

   button#buysell-mobile-2 {
     padding: 0;
     margin: 0;
   }

   .MuiBox-root {
     width: 100% !important;
     padding: 0;
   }

   button#buysell-tab-0 {
     padding: 0;
     color: green !important;
   }

   button#buysell-tab-1 {
     padding: 0;
     color: red !important;
   }

   .MuiTabs-flexContainer,
   .MuiTabs-flexContainer {
     justify-content: center;
   }

   .btn-limit-sell-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradered);
     border: 0;
     margin-top: 39px;
   }

   .btn-limit-buy-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradegreen);
     border: 0;
     margin-top: 39px;
   }

   .btn-market-sell-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradered);
     border: 0;
     margin-top: 40px;
   }

   .btn-market-buy-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradegreen);
     border: 0;
     margin-top: 40px;
   }

   .btn-stoplimit-sell-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradered);
     border: 0;
     margin-top: 5px;
   }

   .btn-stoplimit-buy-mobile {
     width: 100%;
     height: 35px;
     line-height: 10px;
     background-color: var(--tradegreen);
     border: 0;
     margin-top: 5px;
   }
 }